import axios from "axios";
import { toast } from "react-toastify";

import { EmailTemplate } from "./EmailTemplate";

const EMAIL_TEMPLATES_API_URL = `${process.env.REACT_APP_API_BASE_URL}/emailTemplates`;
const COMMUNICATIONS_TEST_API_URL = `${process.env.REACT_APP_API_BASE_URL}/communications/test`;

export const fetchEmailTemplates = async (departmentId: string) => {
  try {
    const response = await axios.get(`${EMAIL_TEMPLATES_API_URL}/by-department/${departmentId}`);
    const emailTemplates: EmailTemplate[] = response.data.map((emailTemplate: any) => ({
      id: `${emailTemplate.id}`,
      departmentId: `${emailTemplate.departmentId}`,
      emailType: emailTemplate.emailType,
      subject: emailTemplate.subject,
      body: emailTemplate.body,
    }));
    return emailTemplates;
  } catch (err) {
    return [];
  }
};

export const updateEmailTemplate = async (updatedEmailTemplate: EmailTemplate) => {
  const payload = {
    id: parseInt(updatedEmailTemplate.id),
    departmentId: parseInt(updatedEmailTemplate.departmentId),
    subject: updatedEmailTemplate.subject,
    body: updatedEmailTemplate.body,
  };
  await axios.put(EMAIL_TEMPLATES_API_URL, payload);
  toast.success("Sablonul a fost actualizat cu succes!");
};

export const testEmailTemplate = async (emailTemplate: EmailTemplate, testEmail: string) => {
  const payload = {
    departmentId: parseInt(emailTemplate.departmentId),
    emailType: parseInt(emailTemplate.id),
    to: testEmail,
  };
  await axios.post(COMMUNICATIONS_TEST_API_URL, payload);
  toast.success("Emailul de test a fost trimis!");
};
