import { useState } from "react";
import { useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";

import { ReportsFilters } from "./ReportsFilters";
import {
  convertDateStringToDate,
  convertDateToDateString,
  setEndOfDayToDateString,
  setStartOfDayToDateString,
} from "../../common/utils/dateHelper";
import { UserRoleEnum } from "../userRoles/UserRole";
import { Privilege } from "../auth/Privilege";
import { selectProfile } from "../auth/authSlice";
import { LS_SELECTED_DEPARTMENT_ID } from "../../common/utils/common";
import FormGroup from "../../common/components/form/FormGroup";
import TeamsDropdown from "../teams/TeamsDropdown";
import DepartmentsDropdown from "../departments/DepartmentsDropdown";
import UsersDropdown from "../users/UsersDropdown";
import ProtectedChildren from "../../common/routing/ProtectedChildren";
import ReportsFiltersRelativeDates, { relativeDates } from "./ReportsFiltersRelativeDates";
import TicketStatusDropdownGrouped from "../ticketStatuses/TicketStatusDropdownGrouped";
import TicketTypeDropdown from "../ticketType/TicketTypeDropdown";

interface ReportsFiltersFormProps {
  onSubmit?: (filters: ReportsFilters) => void;
}

export default function ReportsFiltersForm(props: ReportsFiltersFormProps) {
  const profile = useSelector(selectProfile);
  const { control, handleSubmit, watch, setValue } = useForm<ReportsFilters>({
    defaultValues: {
      startDate: relativeDates.currentMonth.startDate!,
      endDate: relativeDates.currentMonth.endDate!,
      departmentId: localStorage.getItem(LS_SELECTED_DEPARTMENT_ID) || profile.departmentId,
      agentId: profile.role === UserRoleEnum.Agent ? profile.id : undefined,
    },
  });
  const [relativeDateId, setRelativeDateId] = useState(relativeDates.currentMonth.id);
  const watchedDepartmentId = watch("departmentId");
  const watchedTeamId = watch("teamId");

  const onSubmit = (data: ReportsFilters) => {
    props.onSubmit?.({
      ...data,
      startDate: setStartOfDayToDateString(data.startDate)!,
      endDate: setEndOfDayToDateString(data.endDate)!,
    });
  };

  return (
    <div className="flex flex-column gap-4">
      <ReportsFiltersRelativeDates
        value={relativeDateId}
        onChange={(relativeDateId) => {
          setRelativeDateId(relativeDateId);
          setValue("startDate", relativeDates[relativeDateId].startDate);
          setValue("endDate", relativeDates[relativeDateId].endDate);
        }}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid align-items-end">
          <div className="col-12 sm:col-6">
            <FormGroup size="small" label="Data inceput">
              <Controller
                name="startDate"
                control={control}
                render={({ field }) => (
                  <Calendar
                    showIcon
                    dateFormat="dd/mm/yy"
                    value={convertDateStringToDate(field.value)}
                    onChange={(e) => field.onChange(convertDateToDateString(e.target.value ?? undefined))}
                  />
                )}
              />
            </FormGroup>
          </div>
          <div className="col-12 sm:col-6">
            <FormGroup size="small" label="Data sfarsit">
              <Controller
                name="endDate"
                control={control}
                render={({ field }) => (
                  <Calendar
                    showIcon
                    dateFormat="dd/mm/yy"
                    value={convertDateStringToDate(field.value)}
                    onChange={(e) => field.onChange(convertDateToDateString(e.target.value ?? undefined))}
                  />
                )}
              />
            </FormGroup>
          </div>
        </div>
        <ProtectedChildren requiredPrivilege={Privilege.Admin}>
          <div className="grid align-items-end">
            <div className="col-12 sm:col-4">
              <FormGroup size="small" label="Departament">
                <Controller
                  name="departmentId"
                  control={control}
                  render={({ field }) => (
                    <DepartmentsDropdown
                      value={field.value}
                      onChange={(departmentId) => {
                        setValue("teamId", undefined);
                        setValue("agentId", undefined);
                        field.onChange(departmentId);
                      }}
                    />
                  )}
                />
              </FormGroup>
            </div>
            {watchedDepartmentId && (
              <div className="col-12 sm:col-4">
                <FormGroup size="small" label="Grupa">
                  <Controller
                    name="teamId"
                    control={control}
                    render={({ field }) => (
                      <TeamsDropdown
                        showClear
                        placeholder="Selecteaza grupa"
                        departmentId={watchedDepartmentId}
                        value={field.value}
                        onChange={(teamId) => {
                          setValue("agentId", undefined);
                          field.onChange(teamId);
                        }}
                      />
                    )}
                  />
                </FormGroup>
              </div>
            )}
            {watchedTeamId && (
              <div className="col-12 sm:col-4">
                <FormGroup size="small" label="Agent">
                  <Controller
                    name="agentId"
                    control={control}
                    render={({ field }) => (
                      <UsersDropdown
                        showClear
                        placeholder="Selecteaza agent"
                        teamIds={[watchedTeamId]}
                        role={UserRoleEnum.Agent}
                        value={field.value}
                        onChange={field.onChange}
                      />
                    )}
                  />
                </FormGroup>
              </div>
            )}
          </div>
        </ProtectedChildren>
        <div className="grid align-items-end">
          <div className="col-12 sm:col-4">
            <FormGroup size="small" label="Tip tichete">
              <Controller
                name="ticketType"
                control={control}
                render={({ field }) => (
                  <TicketTypeDropdown showClear placeholder="Selecteaza tip tichet" value={field.value} onChange={field.onChange} />
                )}
              />
            </FormGroup>
          </div>
          <div className="col-12 sm:col-4">
            <FormGroup size="small" label="Status tichete">
              <Controller
                name="ticketStatuses"
                control={control}
                render={({ field }) => (
                  <TicketStatusDropdownGrouped
                    showClear
                    placeholder="Selecteaza status tichet"
                    value={field.value}
                    onChange={field.onChange}
                  />
                )}
              />
            </FormGroup>
          </div>
          <div className="col-12 sm:col-4">
            <Button type="submit" className="mb-1" label="Genereaza" icon="pi pi-chart-pie"></Button>
          </div>
        </div>
      </form>
    </div>
  );
}
