import axios from "axios";
import { toast } from "react-toastify";

import { Category } from "./Category";
import { FileUploadResult } from "../../common/types/FileUploadResult";

const CATEGORIES_API_URL = `${process.env.REACT_APP_API_BASE_URL}/categories`;

export const fetchCategoriesByDepartment = async (departmentId: string) => {
  try {
    const response = await axios.get(`${CATEGORIES_API_URL}/by-department/${departmentId}`);
    const categories: Category[] = response.data.map(mapApiCategory);
    return categories;
  } catch (err) {
    return [];
  }
};

export const fetchCategory = async (categoryId: string) => {
  const response = await axios.get(`${CATEGORIES_API_URL}/${categoryId}`);
  return mapApiCategory(response.data);
};

export const createCategory = async (category: Category) => {
  const response = await axios.post(CATEGORIES_API_URL, category);
  const newCategory = mapApiCategory(response.data);
  toast.success("Categoria a fost creata cu succes!");
  return newCategory;
};

export const updateCategory = async (category: Category) => {
  await axios.put(`${CATEGORIES_API_URL}`, category);
  toast.success("Categoria a fost actualizata cu succes!");
};

export const deleteCategory = async (category: Category) => {
  await axios.delete(`${CATEGORIES_API_URL}/${category.id}`);
  toast.success("Categoria a fost stearsa cu succes!");
};

export const uploadIcon = async (file: File) => {
  try {
    const formData = new FormData();
    formData.append("file", file);
    const response = await axios.post(`${CATEGORIES_API_URL}/file`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    toast.success("Imaginea a fost incarcata cu succes!");
    return response.data as FileUploadResult;
  } catch (err) {
    toast.error(`Eroare la incarcarea fisierului ${file.name}`);
    return null;
  }
};

export const mapApiCategory = (category: any): Category => ({
  id: `${category.id}`,
  departmentId: `${category.departmentId}`,
  parentId: category.parentId && `${category.parentId}`,
  level: category.level,
  name: category.name,
  icon: category.icon,
  fileName: category.fileName,
  fileUri: category.fileUri && `${process.env.REACT_APP_SERVER_BASE_URL}${category.fileUri}`,
  ticketType: category.ticketType,
  isActive: category.isActive,
});
