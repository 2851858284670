import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

import { RootState } from "../../common/store/store";
import { DayOfWeek } from "./DayOfWeek";

const DAYS_OF_WEEK_API_URL = `${process.env.REACT_APP_API_BASE_URL}/common/daysOfWeek`;

const daysOfWeekAdapter = createEntityAdapter<DayOfWeek>({});

const initialState = daysOfWeekAdapter.getInitialState();

const daysOfWeekSlice = createSlice({
  name: "daysOfWeek",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchDaysOfWeek.fulfilled, (state, action) => {
      daysOfWeekAdapter.setAll(state, action.payload);
    });
  },
});

export const fetchDaysOfWeek = createAsyncThunk("daysOfWeek/fetchDaysOfWeek", async () => {
  try {
    const response = await axios.get(DAYS_OF_WEEK_API_URL);
    const daysOfWeek: DayOfWeek[] = response.data.map((dayOfWeek: any) => ({
      id: `${dayOfWeek.id}`,
      name: dayOfWeek.name,
    }));
    return daysOfWeek;
  } catch (err) {
    return [];
  }
});

export const { selectAll: selectAllDaysOfWeek, selectById: selectDayOfWeekById } = daysOfWeekAdapter.getSelectors(
  (state: RootState) => state.daysOfWeek,
);

export default daysOfWeekSlice.reducer;
