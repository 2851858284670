import { useSelector } from "react-redux";

import { Privilege } from "../auth/Privilege";
import { canAccess } from "../auth/utils";
import { selectAuthUser } from "../auth/authSlice";
import { Ticket } from "./Ticket";
import { TicketStatusEnum } from "../ticketStatuses/TicketStatus";

export default function useAreTicketActionsAllowed(ticket?: Ticket) {
  const authUser = useSelector(selectAuthUser);
  const isAdmin = canAccess(Privilege.Admin, authUser.privilege);
  if (!ticket) {
    return false;
  }
  if (ticket.status === TicketStatusEnum.Closed) {
    return false;
  }
  if (ticket.status === TicketStatusEnum.Resolved) {
    return isAdmin;
  }

  return true;
}
