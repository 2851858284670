import { Route, Routes } from "react-router-dom";

import { routes } from "./routes";
import { Privilege } from "../../features/auth/Privilege";
import { TicketType } from "../../features/ticketType/TicketType";
import DashboardPage from "../../features/dashboard/DashboardPage";
import CreateTicketPage from "../../features/tickets/CreateTicketPage";
import EmailTemplatesPage from "../../features/emailTemplates/EmailTemplatesPage";
import PrioritiesPage from "../../features/priorities/PrioritiesPage";
import WorkingHoursPage from "../../features/workingHours/WorkingHoursPage";
import QuestionsPage from "../../features/questions/QuestionsPage";
import DepartmentsPage from "../../features/departments/DepartmentsPage";
import LoginPage from "../../features/auth/LoginPage";
import UnauthorizedPage from "../../features/auth/UnauthorizedPage";
import UsersPage from "../../features/users/UsersPage";
import MyProfile from "../../features/myProfile/MyProfile";
import NotFoundPage from "../pages/NotFoundPage";
import ProtectedRoute from "./ProtectedRoute";
import PrivateLayout from "../components/layout/PrivateLayout";
import PublicLayout from "../components/layout/PublicLayout";
import LogoutPage from "../../features/auth/LogoutPage";
import CategoriesPage from "../../features/categories/CategoriesPage";
import SystemSettingsPage from "../../features/systemSettings/SystemSettingsPage";
import TicketsPage from "../../features/tickets/TicketsPage";
import AgentTicketDetailsPage from "../../features/tickets/AgentTicketDetailsPage";
import UserTicketDetailsPage from "../../features/tickets/UserTicketDetailsPage";
import TeamsPage from "../../features/teams/TeamsPage";
import TicketStatusesPage from "../../features/ticketStatuses/TicketStatusesPage";
import ReportsPage from "../../features/reports/ReportsPage";

export default function Router() {
  return (
    <Routes>
      <Route element={<PublicLayout />}>
        <Route path="*" element={<NotFoundPage />} />
        <Route path={routes.login.path} element={<LoginPage />} />
        <Route path={routes.unauthorized.path} element={<UnauthorizedPage />} />
        <Route path={routes.logout.path} element={<LogoutPage />} />
      </Route>
      <Route element={<PrivateLayout />}>
        <Route element={<ProtectedRoute requiredPrivilege={Privilege.User} />}>
          <Route path={routes.dashboard.path} element={<DashboardPage />} />
          <Route
            path={routes.createIncident.path}
            element={<CreateTicketPage key={routes.createIncident.path} ticketType={TicketType.Incident} />}
          />
          <Route
            path={routes.createRequest.path}
            element={<CreateTicketPage key={routes.createRequest.path} ticketType={TicketType.Request} />}
          />
          <Route path={routes.userTicketDetails.path} element={<UserTicketDetailsPage />} />
        </Route>
        <Route element={<ProtectedRoute requiredPrivilege={Privilege.Agent} />}>
          <Route path={routes.agentTicketDetails.path} element={<AgentTicketDetailsPage />} />
          <Route path={routes.agentTickets.path} element={<TicketsPage />} />
          <Route path={routes.questions.path} element={<QuestionsPage />} />
          <Route path={routes.myProfile.path} element={<MyProfile />} />
        </Route>
        <Route element={<ProtectedRoute requiredPrivilege={Privilege.Admin} />}>
          <Route path={routes.emailTemplates.path} element={<EmailTemplatesPage />} />
          <Route path={routes.priorities.path} element={<PrioritiesPage />} />
          <Route path={routes.workingHours.path} element={<WorkingHoursPage />} />
          <Route path={routes.departments.path} element={<DepartmentsPage />} />
          <Route path={routes.users.path} element={<UsersPage />} />
          <Route path={routes.categories.path} element={<CategoriesPage />} />
          <Route path={routes.systemSetting.path} element={<SystemSettingsPage />} />
          <Route path={routes.teams.path} element={<TeamsPage />} />
          <Route path={routes.ticketStatuses.path} element={<TicketStatusesPage />} />
          <Route path={routes.reports.path} element={<ReportsPage />} />
        </Route>
      </Route>
    </Routes>
  );
}
