import { useEffect, useRef, useState } from "react";
import { FileUpload, FileUploadFile, FileUploadHandlerEvent } from "primereact/fileupload";
import { Message } from "primereact/message";
import { Editor } from "primereact/editor";

import { PropsWithClassName } from "../../common/types/PropsWithClassName";
import { uploadFile } from "./notesApi";
import { NoteAttachment } from "./Note";
import { checkIfHtmlStringHasText } from "../../common/utils/common";
import QuillBar from "../../common/components/quillBar/QuillBar";

interface NoteChangeEvent {
  noteText?: string;
  noteAttachments?: NoteAttachment[];
}

interface NoteFormInputProps extends PropsWithClassName {
  noteText?: string;
  noteAttachments?: NoteAttachment[];
  errorMessage?: string;
  onChange: (noteChangeEvent: NoteChangeEvent) => void;
}

const allowedExtensions = [
  ".txt",
  ".svg",
  ".odp",
  ".odt",
  ".ods",
  ".csv",
  ".7z",
  ".zip",
  ".rar",
  ".bmp",
  ".msg",
  ".eml",
  ".png",
  ".jpg",
  ".jpeg",
  ".doc",
  ".docx",
  ".pdf",
  ".xls",
  ".xlsx",
  ".ppt",
  ".pptx",
].join(",");

export const noteFormInputDefaultValidation = {
  required: (value: NoteChangeEvent) => {
    const hasAttachments = value?.noteAttachments?.length;
    const hasDescription = (value?.noteText || "").trim();
    const hasDescriptionText = hasDescription && checkIfHtmlStringHasText(value.noteText);
    return hasDescriptionText ? true : hasAttachments ? true : "Descrierea sau un fisier atasat este obligatoriu";
  },
  length: (value: NoteChangeEvent) => {
    const noteText = (value?.noteText || "").trim();
    if (noteText.length > 4000) {
      return "Descrierea trebuie sa aiba maxim 4000 caractere";
    }
  },
};

export default function NoteFormInput(props: NoteFormInputProps) {
  const fileUploadRef = useRef<FileUpload>(null);
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [attachments, setAttachments] = useState<NoteAttachment[]>([]);

  useEffect(() => {
    if (!props.noteAttachments?.length) {
      fileUploadRef.current?.clear();
      setUploadedFiles([]);
      setAttachments([]);
    }
  }, [props.noteAttachments]);

  const onTextChange = (value: string) => {
    props.onChange({
      noteText: value,
    });
  };

  const onRemove = async () => {
    setTimeout(() => {
      const currentUploadedFiles = fileUploadRef.current?.getUploadedFiles() || [];
      const currentAttachments = attachments.filter((attachment) =>
        currentUploadedFiles.some((file) => file.name === attachment.originalFileName),
      );

      setUploadedFiles(currentUploadedFiles);
      setAttachments(currentAttachments);
      props.onChange({ noteAttachments: currentAttachments });
    });
  };

  const onUpload = async (event: FileUploadHandlerEvent) => {
    const newUploadedFiles: File[] = [...uploadedFiles];
    const newAttachments: NoteAttachment[] = [...attachments];

    for (const file of event.files) {
      if (attachments.some((attachment) => attachment.originalFileName === file.name)) {
        continue;
      }
      const result = await uploadFile(file);
      if (result) {
        newUploadedFiles.push(file);
        newAttachments.push({ fileName: result.fileName, originalFileName: file.name });
      }
    }
    fileUploadRef.current?.clear();
    fileUploadRef.current?.setUploadedFiles(newUploadedFiles as FileUploadFile[]);

    setUploadedFiles(newUploadedFiles);
    setAttachments(newAttachments);
    props.onChange({ noteAttachments: newAttachments });
  };

  return (
    <div className={`${props.className} flex flex-column gap-2`}>
      <Editor
        style={{ height: "100px" }}
        headerTemplate={<QuillBar />}
        value={props.noteText || ""}
        onTextChange={(e) => onTextChange(e.htmlValue || "")}
      ></Editor>
      {props.errorMessage && <Message severity="error" text={props.errorMessage}></Message>}
      <FileUpload
        mode="advanced"
        auto
        multiple
        customUpload
        chooseLabel="Adauga fisiere"
        uploadLabel="Incarca"
        cancelLabel="Renunta"
        invalidFileSizeMessageDetail="Fisierul depaseste dimensiunea maxima permisa de 10MB"
        emptyTemplate={
          <div className="h-7rem p-3 -my-3 border-1 border-dashed border-400">
            Trageti fisierele aici sau folositi butonul &apos;Adauga Fisiere&rsquo; pentru a le incarca
          </div>
        }
        ref={fileUploadRef}
        accept={allowedExtensions}
        maxFileSize={10 * 1024 * 1024}
        uploadHandler={onUpload}
        onRemove={onRemove}
      />
    </div>
  );
}
