import { useEffect, useState } from "react";
import { MultiSelect } from "primereact/multiselect";

import { fetchTicketStatusesByDepartment } from "./ticketStatusesApi";
import { TicketStatus, TicketStatusEnum } from "./TicketStatus";

interface TicketStatusMultiSelectProps {
  departmentId: string;
  value?: string[];
  showClear?: boolean;
  onChange: (ticketStatusIds: string[]) => void;
}

export default function TicketStatusMultiSelect({ departmentId, value, showClear, onChange }: TicketStatusMultiSelectProps) {
  const [ticketStatuses, setTicketStatuses] = useState<TicketStatus[]>([]);
  const selectedTicketStatuses = ticketStatuses.filter((ticketStatus) => value?.includes(ticketStatus.id));

  useEffect(() => {
    const doFetchTicketStatuses = async () => {
      if (departmentId) {
        const ticketStatuses = await fetchTicketStatusesByDepartment(departmentId);
        const allowedTicketStatuses = ticketStatuses.filter((ticketStatus) => ticketStatus.id !== TicketStatusEnum.Merged);
        setTicketStatuses(allowedTicketStatuses);
      }
    };
    doFetchTicketStatuses();
  }, [departmentId]);

  return (
    <MultiSelect
      optionLabel="name"
      options={ticketStatuses}
      value={selectedTicketStatuses}
      showClear={showClear}
      onChange={(e) => onChange(e.value.map((ticketStatus: TicketStatus) => ticketStatus.id))}
    ></MultiSelect>
  );
}
