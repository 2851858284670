import { User } from "./User";
import UserAvatar from "./UserAvatar";

interface UserDropdownItemProps {
  user: User;
}

export default function UserDropdownItem({ user }: UserDropdownItemProps) {
  return (
    <div className="flex align-items-center gap-2">
      <UserAvatar user={user}></UserAvatar>
      <span className="text-ellipsis">
        {user.firstName} {user.lastName}
      </span>
    </div>
  );
}
