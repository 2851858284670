import axios from "axios";
import { toast } from "react-toastify";
import { format } from "date-fns";
import ro from "date-fns/locale/ro";

import { SearchTicketsFilter, Ticket } from "./Ticket";
import { convertISOStringToDate } from "../../common/utils/dateHelper";
import { emptyPaginationDetails, mapApiPaginationDetails, mapToApiPagination } from "../../common/api/paginationHelper";
import { mapApiCategory } from "../categories/categoriesApi";
import { mapApiUser } from "../users/usersApi";
import { Note } from "../note/Note";
import { mapApiTeam } from "../teams/teamsApi";
import { TicketType } from "../ticketType/TicketType";
import { mapApiTicketStatus } from "../ticketStatuses/ticketStatusesApi";

const TICKETS_API_URL = `${process.env.REACT_APP_API_BASE_URL}/tickets`;

export const searchOwnTickets = async (filter: SearchTicketsFilter) => {
  return doSearch(filter, `${TICKETS_API_URL}/search`);
};

export const searchAllTickets = async (filter: SearchTicketsFilter) => {
  return doSearch(filter, `${TICKETS_API_URL}/search-all`);
};

export const quickSearchTickets = async (ticketId: string) => {
  const payload = {
    id: parseInt(ticketId),
    ...mapToApiPagination({
      pageNumber: 0,
      pageSize: 10,
    }),
  };
  const response = await axios.post(`${TICKETS_API_URL}/search-all`, payload);
  const tickets: Ticket[] = response.data.items.map(mapApiTicket);
  return tickets;
};

export const fetchTicket = async (ticketId: string) => {
  const response = await axios.get(`${TICKETS_API_URL}/${ticketId}`);
  return mapApiTicket(response.data);
};

export const createTicket = async (ticket: Ticket, note: Note) => {
  const payload = {
    type: ticket.type,
    ownerId: ticket.ownerId,
    teamId: ticket.teamId && parseInt(ticket.teamId),
    agentId: ticket.agentId,
    categoryId: parseInt(ticket.categoryId),
    urgency: parseInt(ticket.urgency),
    subject: ticket.subject,
    description: note.noteText || "",
    attachments: note.noteAttachments || [],
  };
  const response = await axios.post(TICKETS_API_URL, payload);
  const newTicket = mapApiTicket(response.data);
  toast.success(`Tichetul ${newTicket.prettyId} a fost creat cu succes!`);
  return newTicket;
};

export const deleteTicket = async (ticketId: string) => {
  await axios.delete(`${TICKETS_API_URL}/${ticketId}`);
  toast.success("Tichetul a fost sters cu succes!");
};

export const mergeTickets = async (ticketIds: string[], targetTicketId: string) => {
  const payload = {
    ticketIds: ticketIds.map((id) => parseInt(id)),
    targetTicketId: parseInt(targetTicketId),
  };
  await axios.put(`${TICKETS_API_URL}/merge-tickets`, payload);
  toast.success("Tichet parinte asignat cu succes!");
};

export const changeStatus = async (ticketIds: string[], status: string) => {
  const payload = {
    ticketIds: ticketIds.map((id) => parseInt(id)),
    status: parseInt(status),
  };
  await axios.put(`${TICKETS_API_URL}/change-status`, payload);
  toast.success("Status schimbat cu succes!");
};

export const changeTeam = async (ticketIds: string[], teamId?: string) => {
  const payload = {
    ticketIds: ticketIds.map((id) => parseInt(id)),
    teamId,
  };
  await axios.put(`${TICKETS_API_URL}/assign-to-team`, payload);
  toast.success("Grupa atribuita cu succes!");
};

export const changeAgent = async (ticketIds: string[], agentId?: string) => {
  const payload = {
    ticketIds: ticketIds.map((id) => parseInt(id)),
    agentId,
  };
  await axios.put(`${TICKETS_API_URL}/assign-to-agent`, payload);
  toast.success("Agent atribuit cu succes!");
};

export const changeOwner = async (ticketIds: string[], ownerId: string) => {
  const payload = {
    ticketIds: ticketIds.map((id) => parseInt(id)),
    ownerId,
  };
  await axios.put(`${TICKETS_API_URL}/assign-to-owner`, payload);
  toast.success("Utilizator atribuit cu succes!");
};

export const changeUrgency = async (ticketIds: string[], urgency: string) => {
  const payload = {
    ticketIds: ticketIds.map((id) => parseInt(id)),
    urgency: parseInt(urgency),
  };
  await axios.put(`${TICKETS_API_URL}/change-urgency`, payload);
  toast.success("Prioritatea schimbata cu succes!");
};

export const changeCategory = async (ticketIds: string[], type: TicketType, categoryId: string) => {
  const payload = {
    ticketIds: ticketIds.map((id) => parseInt(id)),
    type,
    categoryId: parseInt(categoryId),
  };
  await axios.put(`${TICKETS_API_URL}/change-type`, payload);
  toast.success("Categoria schimbata cu succes!");
};

export const mapApiTicket = (apiTicket: any): Ticket => {
  const created = convertISOStringToDate(`${apiTicket.created}Z`)!;
  const slaBeginDate = apiTicket.slaBeginDate ? convertISOStringToDate(`${apiTicket.slaBeginDate}Z`) : undefined;
  const slaEndDate = apiTicket.slaEndDate ? convertISOStringToDate(`${apiTicket.slaEndDate}Z`) : undefined;

  return {
    id: `${apiTicket.id}`,
    prettyId: getTicketPrettyId(apiTicket.id),
    type: apiTicket.type,
    replacedById: apiTicket.replacedById && `${apiTicket.replacedById}`,
    prettyReplacedById: apiTicket.replacedById && getTicketPrettyId(apiTicket.replacedById),
    teamId: `${apiTicket.teamId}`,
    team: apiTicket.team && mapApiTeam(apiTicket.team),
    ownerId: apiTicket.ownerId,
    owner: apiTicket.owner && mapApiUser(apiTicket.owner),
    agentId: apiTicket.agentId,
    agent: apiTicket.agent && mapApiUser(apiTicket.agent),
    company: apiTicket.company,
    location: apiTicket.location,
    categoryId: `${apiTicket.categoryId}`,
    category: apiTicket.category && mapApiCategory(apiTicket.category),
    subject: apiTicket.subject,
    urgency: `${apiTicket.urgency}`,
    businessImpact: `${apiTicket.businessImpact}`,
    status: `${apiTicket.status}`,
    statusType: apiTicket.statusType && mapApiTicketStatus(apiTicket.statusType),
    mergedTickets: (apiTicket.mergedTickets || []).map(mapApiTicket),
    cost: { costCenter: apiTicket.costCenter, cost: apiTicket.cost },
    createdTimestamp: created.getTime(),
    createdPretty: format(created, "dd/MM/yyyy HH:mm"),
    slaBeginDateTimestamp: slaBeginDate && slaBeginDate.getTime(),
    slaBeginDatePretty: slaBeginDate && format(slaBeginDate, "EEEE dd MMM HH:mm"),
    slaEndDateTimestamp: slaEndDate && slaEndDate.getTime(),
    slaEndDatePretty: slaEndDate && format(slaEndDate, "EEEE dd MMM HH:mm", { locale: ro }),
    slaPercent: apiTicket.slaPercent,
  };
};

export const getTicketPrettyId = (ticketId: string) => {
  return `#` + `${ticketId}`.padStart(7, "0");
};

const doSearch = async (filter: SearchTicketsFilter, url: string) => {
  try {
    const payload = {
      departmentId: filter.departmentId ? parseInt(filter.departmentId) : undefined,
      id: filter.id ? parseInt(filter.id) : undefined,
      ownerId: filter.ownerId,
      teamId: filter.teamId,
      agentId: filter.agentId,
      statuses: (filter.statuses || []).map((status) => parseInt(status)),
      urgency: filter.urgency ? parseInt(filter.urgency) : undefined,
      sortParameters: filter.sortParameters,
      ...mapToApiPagination(filter),
    };
    const response = await axios.post(url, payload);
    const pagination = mapApiPaginationDetails(response.data);
    const tickets: Ticket[] = response.data.items.map(mapApiTicket);
    return {
      pagination,
      tickets,
    };
  } catch (err) {
    return {
      pagination: emptyPaginationDetails,
      tickets: [],
    };
  }
};
