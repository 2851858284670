import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { confirmDialog } from "primereact/confirmdialog";
import { Dialog } from "primereact/dialog";
import { TabPanel, TabView } from "primereact/tabview";
import { Divider } from "primereact/divider";

import UrgencyTag from "../urgencies/UrgencyTag";
import FormGroup from "../../common/components/form/FormGroup";
import { Ticket } from "../tickets/Ticket";
import { deleteTicket, fetchTicket } from "../tickets/ticketsApi";
import TicketActions from "./TicketActions";
import { Note } from "../note/Note";
import { createNote } from "../note/notesApi";
import TicketAddNoteForm from "./TicketAddNoteForm";
import { Privilege } from "../auth/Privilege";
import ProtectedChildren from "../../common/routing/ProtectedChildren";
import TicketMerge from "./TicketMerge";
import { LayoutContext } from "../../common/components/layout/LayoutContext";
import { useAppSelector } from "../../common/hooks/useStore";
import useAreTicketActionsAllowed from "../tickets/useAreTicketActionsAllowed";
import { TicketNotes, TicketNotesRef } from "./TicketNotes";
import { NoteType } from "../note/NoteType";
import { TicketStatusEnum } from "../ticketStatuses/TicketStatus";
import { useReplacementTicketWarning } from "../tickets/useReplacementTicketWarning";
import { routes } from "../../common/routing/routes";
import MergedTicketsList from "./MergeTicketsList";
import TicketCostForm from "../ticketCost/TicketCostForm";
import { selectDepartmentById } from "../departments/departmentsSlice";

export default function AgentTicketDetailsPage() {
  const { setFullContentWidth, setStandardContentWidth } = useContext(LayoutContext);
  const navigate = useNavigate();
  const { ticketId } = useParams();
  const ticketNotesRef = useRef<TicketNotesRef>(null);
  const systemNotesRef = useRef<TicketNotesRef>(null);
  const [ticket, setTicket] = useState<Ticket>();
  const [showMergeDialog, setShowMergeDialog] = useState(false);
  const department = useAppSelector((state) => selectDepartmentById(state, ticket?.category?.departmentId));
  const areTicketActionsAllowed = useAreTicketActionsAllowed(ticket);
  const shouldReopen = ticket?.status === TicketStatusEnum.Resolved;

  useReplacementTicketWarning(routes.agentTicketDetails.navigatePath, ticket);

  useEffect(() => {
    setFullContentWidth();
    return () => setStandardContentWidth();
  }, []);

  useEffect(() => {
    if (ticketId) {
      doFetchTicket(ticketId);
    }
  }, [ticketId]);

  const doFetchTicket = async (ticketId: string) => {
    const ticket = await fetchTicket(ticketId);
    setTicket(ticket);
  };

  const doCreateNote = async (newNote: Partial<Note>) => {
    await createNote(newNote);
    await doFetchTicket(ticketId!);
    ticketNotesRef?.current?.refreshNotes();
  };

  const onDelete = async () => {
    confirmDialog({
      header: "Confirmare stergere",
      icon: "pi pi-exclamation-triangle",
      message: (
        <div className="text-xl">
          Esti sigur ca vrei sa stergi tichetul <span className="font-bold">{ticket?.prettyId}</span>?
        </div>
      ),
      acceptLabel: "Da",
      rejectLabel: "Nu",
      accept: async () => {
        await deleteTicket(ticketId!);
        navigate(-1);
      },
    });
  };

  const onTicketActionFinished = async () => {
    await doFetchTicket(ticketId!);
    systemNotesRef?.current?.refreshNotes();
  };

  const onTicketMergeFinished = () => {
    navigate(routes.agentTickets.path);
    setShowMergeDialog(false);
  };

  return ticket ? (
    <div className="grid">
      <div className="col-12 xl:col-8">
        <TabView className="mb-4" panelContainerClassName="py-2 px-0 bg-transparent">
          <TabPanel header="Note tichet">
            <TicketNotes ref={ticketNotesRef} ticketId={ticketId!} noteTypes={[NoteType.External, NoteType.Internal]} />
          </TabPanel>
          <TabPanel header="Istoric tichet">
            <TicketNotes ref={systemNotesRef} ticketId={ticketId!} noteTypes={[NoteType.System]} />
          </TabPanel>
          {ticket.mergedTickets.length ? (
            <TabPanel header="Tichete copii">
              <MergedTicketsList mergedTickets={ticket.mergedTickets}></MergedTicketsList>
            </TabPanel>
          ) : null}
        </TabView>
        <Card className="mb-4" title="Adauga nota">
          <TicketAddNoteForm
            showInternal={!!department?.internalNotesEnabled}
            ticketId={ticketId!}
            reopen={shouldReopen}
            disabled={shouldReopen ? false : !areTicketActionsAllowed}
            onSubmit={doCreateNote}
          ></TicketAddNoteForm>
        </Card>
        {department?.costCentersEnabled && (
          <Card title="Adauga cost">
            <TicketCostForm ticket={ticket} readonly={!areTicketActionsAllowed}></TicketCostForm>
          </Card>
        )}
      </div>
      <div className="col-12 xl:col-4">
        <Card
          title={
            <div className="flex align-items-center gap-2">
              <span>Tichet {ticket.prettyId}</span>
              <UrgencyTag value={ticket.urgency}></UrgencyTag>
            </div>
          }
        >
          <FormGroup label="Titlu">
            <span className="text-600 break-all">{ticket.subject}</span>
          </FormGroup>
          <FormGroup label="Utilizator">
            <span className="text-600">{ticket.owner?.displayName}</span>
          </FormGroup>
          <FormGroup label="Data sesizarii">
            <span className="text-600">{ticket.createdPretty}</span>
          </FormGroup>
          {ticket.slaEndDatePretty && (
            <FormGroup label="Termen rezolvare">
              <span className="text-600">{ticket.slaEndDatePretty}</span>
            </FormGroup>
          )}
          <Divider className="mt-0" />
          <TicketActions
            className="mb-2"
            ticketIds={[ticket.id]}
            ticketStatus={ticket.status}
            teamId={ticket.teamId}
            agentId={ticket.agentId}
            ownerId={ticket.ownerId}
            urgency={ticket.urgency}
            ticketType={ticket.type}
            departmentId={ticket.category?.departmentId}
            categoryId={ticket.categoryId}
            disabled={!areTicketActionsAllowed}
            onActionFinished={onTicketActionFinished}
          ></TicketActions>
          <div className="flex justify-content-end gap-2">
            <Button type="button" size="small" label="Inapoi" text severity="secondary" onClick={() => navigate(-1)} />
            <Button
              type="button"
              size="small"
              label="Combina"
              icon="pi pi-clone"
              disabled={!areTicketActionsAllowed}
              onClick={() => setShowMergeDialog(true)}
            ></Button>
            <ProtectedChildren requiredPrivilege={Privilege.Admin}>
              <Button type="button" size="small" label="Sterge" icon="pi pi-trash" severity="danger" onClick={onDelete}></Button>
            </ProtectedChildren>
          </div>
        </Card>
        <Dialog className="w-full sm:w-6" header="Combina tichete" visible={showMergeDialog} onHide={() => setShowMergeDialog(false)}>
          <TicketMerge
            ticketId={ticketId}
            onCancel={() => setShowMergeDialog(false)}
            onSubmit={() => onTicketMergeFinished()}
          ></TicketMerge>
        </Dialog>
      </div>
    </div>
  ) : null;
}
