import { selectUserRoleById } from "./userRolesSlice";
import { useAppSelector } from "../../common/hooks/useStore";

interface UserRoleLabelProps {
  value: string;
}

export default function UserRoleLabel({ value }: UserRoleLabelProps) {
  const role = useAppSelector((state) => selectUserRoleById(state, value));

  return <div>{role?.name}</div>;
}
