import { Tag } from "primereact/tag";

import { NoteType } from "./NoteType";
import UserAvatar from "../users/UserAvatar";
import { Note } from "./Note";
import { UserRoleEnum } from "../userRoles/UserRole";
import { PropsWithClassName } from "../../common/types/PropsWithClassName";
import NoteAttachmentDetails from "./NoteAttachmentDetails";

interface NoteDetailsProps extends PropsWithClassName {
  note: Note;
}

export default function NoteDetails({ className, note }: NoteDetailsProps) {
  return (
    <div className={`${className} flex gap-2 p-3 border-round bg-white shadow-1"`}>
      <UserAvatar user={note.user} />
      <div className="flex flex-column gap-2">
        <div className="flex flex-column gap-1">
          <div className="flex align-items-center gap-2 font-semibold">
            <span>
              {note.user.firstName} {note.user.lastName}
            </span>
            {note.user.role === UserRoleEnum.Agent && <Tag>Agent</Tag>}
            {note.type === NoteType.Internal && (
              <div className="flex align-items-center gap-2 text-sm text-yellow-500">
                <i className="pi pi-eye-slash"></i>
                <div>Nota interna</div>
              </div>
            )}
          </div>
          <span className="text-sm text-500">{note.createdPretty}</span>
        </div>
        <div className="flex flex-column gap-4">
          <div className="raw-html-container" dangerouslySetInnerHTML={{ __html: note.noteText }}></div>
          {!!note.noteAttachments.length && (
            <div className="flex flex-column gap-4">
              {note.noteAttachments.map((attachment) => (
                <NoteAttachmentDetails key={attachment.id} attachment={attachment}></NoteAttachmentDetails>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
