import { useEffect, useState } from "react";
import { Image } from "primereact/image";

import { NoteAttachment } from "./Note";
import { downloadFile, fetchNoteFile } from "./notesApi";

interface NoteAttachmentProps {
  attachment: NoteAttachment;
}

export default function NoteAttachmentDetails({ attachment }: NoteAttachmentProps) {
  const [imageData, setImageData] = useState("");

  useEffect(() => {
    const isImage =
      attachment.originalFileName.endsWith(".jpg") ||
      attachment.originalFileName.endsWith(".jpeg") ||
      attachment.originalFileName.endsWith(".png");
    if (isImage) {
      doFetchNoteFile();
    }
  }, [attachment]);

  const doFetchNoteFile = async () => {
    const imageData = await fetchNoteFile(attachment.id!);
    setImageData(`data:image/png;base64,${imageData}`);
  };

  const downloadAttachment = (attachment: NoteAttachment) => {
    downloadFile(attachment.fileUri!, attachment.originalFileName);
  };

  return (
    <div className="flex gap-2">
      {imageData ? (
        <Image className="object-fit-cover" width="42" height="42" preview src={imageData} alt={attachment.originalFileName} />
      ) : (
        <i style={{ width: "42px", height: "42px", fontSize: "42px" }} className="pi pi-file"></i>
      )}
      <div className="flex align-items-center gap-1" key={attachment.fileName}>
        <i className="pi pi-paperclip"></i>
        <a className="text-primary no-underline break-all" href="#" onClick={() => downloadAttachment(attachment)}>
          {attachment.originalFileName}
        </a>
      </div>
    </div>
  );
}
