import { SelectButton } from "primereact/selectbutton";

import { PropsWithClassName } from "../../common/types/PropsWithClassName";
import { groupedTicketStatusOptions } from "./TicketStatus";

interface TicketStatusButtonsProps extends PropsWithClassName {
  inline?: boolean;
  value?: string[];
  onChange: (ticketStatusIds: string[] | undefined) => void;
}

export default function TicketStatusButtons({ className, inline, value, onChange }: TicketStatusButtonsProps) {
  return (
    <SelectButton
      className={`${className} ${inline ? "" : "w-full"} select-button-small`}
      optionLabel="name"
      options={groupedTicketStatusOptions}
      value={value?.sort()}
      onChange={(e) => onChange(e.target.value)}
    />
  );
}
