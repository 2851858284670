import { useState } from "react";
import { useForm } from "react-hook-form";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";

import { Team } from "../teams/Team";
import FormGroup from "../../common/components/form/FormGroup";

interface TeamFormProps {
  team?: Team;
  onSubmit: (team: Team) => Promise<void>;
}

export default function TeamsForm(props: TeamFormProps) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Team>({
    defaultValues: {
      ...props.team,
    },
  });
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (data: Team) => {
    setIsLoading(true);
    await props.onSubmit(data);
    setIsLoading(false);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormGroup label="Nume" errorMessage={errors.name?.message}>
        <InputText {...register("name", { required: "Numele este obligatoriu" })} />
      </FormGroup>
      <Button type="submit" className="block ml-auto" label="Salveaza" icon="pi pi-save" loading={isLoading} disabled={isLoading} />
    </form>
  );
}
