export const emptyPieChartPlugin = {
  id: "emptyPieChart",
  beforeDraw: (chart: any) => {
    const { datasets } = chart.data;
    const hasData = datasets.some((dataset: any) => dataset.data.some((data: any) => data !== 0));
    if (hasData) {
      return;
    }

    const {
      ctx,
      chartArea: { width, height },
    } = chart;
    ctx.save();

    // Draw outer circle
    ctx.beginPath();
    ctx.arc(width / 2, height / 2, Math.min(width, height) / 2 - 10, 0, 2 * Math.PI);
    ctx.strokeStyle = "black";
    ctx.setLineDash([5, 5]);
    ctx.stroke();
    ctx.setLineDash([]);

    // Draw 'no data' text
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
    ctx.fillStyle = "black";
    ctx.fillText("Fara date", width / 2, height / 2);

    ctx.restore();
  },
};
