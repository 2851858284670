export enum UserRoleEnum {
  User = "0",
  Agent = "1",
  Admin = "2",
}

export interface UserRole {
  id: string;
  name: string;
}
