import axios from "axios";
import { format } from "date-fns";

import { LoadReport } from "./LoadReport";
import { ReportsFilters } from "./ReportsFilters";
import { PaginationOptions } from "../../common/types/Pagination";
import { emptyPaginationDetails, mapApiPaginationDetails, mapToApiPagination } from "../../common/api/paginationHelper";
import { TicketReport } from "./TicketReport";
import { getTicketPrettyId } from "../tickets/ticketsApi";
import { convertISOStringToDate } from "../../common/utils/dateHelper";

const REPORTS_API_URL = `${process.env.REACT_APP_API_BASE_URL}/reports`;

export async function fetchLoadReport(reportsFilters: ReportsFilters) {
  const response = await axios.post(`${REPORTS_API_URL}/summary`, {
    ...reportsFilters,
    ticketStatuses: reportsFilters.ticketStatuses?.map((status) => parseInt(status)),
  });
  const loadReport: LoadReport = response.data;
  return loadReport;
}

export async function fetchTicketsReport(reportsFilters: ReportsFilters, paginationOptions: PaginationOptions) {
  try {
    const response = await axios.post(`${REPORTS_API_URL}/tickets`, {
      ...reportsFilters,
      ...mapToApiPagination(paginationOptions),
      ticketStatuses: reportsFilters.ticketStatuses?.map((status) => parseInt(status)),
    });
    const pagination = mapApiPaginationDetails(response.data);
    const ticketsReport: TicketReport[] = response.data.items.map(mapApiTicketReport);
    return {
      pagination,
      ticketsReport,
    };
  } catch (err) {
    return {
      pagination: emptyPaginationDetails,
      ticketsReport: [],
    };
  }
}

const mapApiTicketReport = (apiTicketReport: any): TicketReport => {
  const resolvedDate = convertISOStringToDate(apiTicketReport.resolvedDate);

  return {
    id: `${apiTicketReport.id}`,
    prettyId: getTicketPrettyId(apiTicketReport.id),
    type: apiTicketReport.type,
    urgency: apiTicketReport.urgency,
    status: apiTicketReport.status,
    categoryName: apiTicketReport.categoryName,
    location: apiTicketReport.location,
    teamName: apiTicketReport.teamName,
    agentName: apiTicketReport.agentName,
    userName: apiTicketReport.userName,
    resolvedDate: resolvedDate ? format(resolvedDate, "dd/MM/yyyy HH:mm") : "",
    slaExceeded: apiTicketReport.slaExceeded,
  };
};
