import { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Divider } from "primereact/divider";

import styles from "./Sidebar.module.css";
import SidebarNavLink from "./SidebarNavLink";
import TicketLabel from "../../../features/tickets/TicketLabel";
import SidebarCategory from "./SidebarCategory";
import { LayoutContext } from "./LayoutContext";
import { homeRoute, routes } from "../../routing/routes";
import { Privilege } from "../../../features/auth/Privilege";
import { TicketType } from "../../../features/ticketType/TicketType";
import { selectAuthUser, selectProfile } from "../../../features/auth/authSlice";
import ProtectedChildren from "../../routing/ProtectedChildren";

export default function Sidebar() {
  const navigate = useNavigate();
  const location = useLocation();
  const profile = useSelector(selectProfile);
  const authUser = useSelector(selectAuthUser);
  const { sidebarIsOpen, closeSidebar } = useContext(LayoutContext);

  useEffect(() => {
    closeSidebar();
  }, [location.pathname]);

  return (
    <div
      className={`
      ${styles.layoutSidebar}
      ${sidebarIsOpen ? "" : styles.layoutSidebarClosed}
      flex flex-column h-full py-4 px-2 surface-section shadow-2 z-2`}
    >
      <div className="mb-4 text-center">
        <img
          className="cursor-pointer"
          height={100}
          src="/images/logo.png"
          alt="Smart Suport Logo"
          onClick={() => navigate(homeRoute(authUser.privilege).path)}
        />
      </div>
      <SidebarCategory
        className="cursor-pointer"
        name="Portal utilizator"
        requiredPrivilege={Privilege.User}
        onClick={() => navigate(routes.dashboard.path)}
      >
        <SidebarNavLink path={routes.dashboard.path} icon="pi-home" text="Tichete utilizator"></SidebarNavLink>
        <SidebarNavLink
          path={routes.createIncident.path}
          text={<TicketLabel ticketType={TicketType.Incident} creating></TicketLabel>}
        ></SidebarNavLink>
        <SidebarNavLink
          path={routes.createRequest.path}
          text={<TicketLabel ticketType={TicketType.Request} creating></TicketLabel>}
        ></SidebarNavLink>
        <Divider />
      </SidebarCategory>
      <SidebarCategory
        className="cursor-pointer"
        name="Portal agent"
        requiredPrivilege={Privilege.Agent}
        onClick={() => navigate(routes.agentTickets.path)}
      >
        <SidebarNavLink path={routes.agentTickets.path} icon="pi-wrench" text="Tichete agenti"></SidebarNavLink>
        <SidebarNavLink path={routes.questions.path} icon="pi-question-circle" text="Intrebari frecvente"></SidebarNavLink>
        <SidebarNavLink path={routes.myProfile.path} icon="pi-id-card" text="Contul meu"></SidebarNavLink>
        <ProtectedChildren requiredPrivilege={Privilege.Admin}>
          <SidebarNavLink path={routes.reports.path} icon="pi-chart-pie" text="Rapoarte"></SidebarNavLink>
        </ProtectedChildren>
        <Divider />
      </SidebarCategory>
      <SidebarCategory
        className="cursor-pointer mb-4"
        name="Portal admin"
        requiredPrivilege={Privilege.Admin}
        onClick={() => navigate(routes.departments.path)}
      >
        <SidebarNavLink path={routes.departments.path} icon="pi-users" text="Departamente"></SidebarNavLink>
        <SidebarNavLink path={routes.categories.path} icon="pi-book" text="Categorii"></SidebarNavLink>
        <SidebarNavLink path={routes.users.path} icon="pi-user" text="Utilizatori"></SidebarNavLink>
        <SidebarNavLink path={routes.teams.path} icon="pi-sitemap" text="Grupe"></SidebarNavLink>
        <SidebarNavLink path={routes.ticketStatuses.path} icon="pi-tags" text="Statusuri tichet"></SidebarNavLink>
        <SidebarNavLink path={routes.priorities.path} icon="pi-clock" text="Configurare SLA"></SidebarNavLink>
        <SidebarNavLink path={routes.emailTemplates.path} icon="pi-envelope" text="Sabloane email"></SidebarNavLink>
        <SidebarNavLink path={routes.workingHours.path} icon="pi-calendar" text="Timpi de operare"></SidebarNavLink>
        <SidebarNavLink path={routes.systemSetting.path} icon="pi-cog" text="Setari sistem"></SidebarNavLink>
      </SidebarCategory>
      <Divider className="mt-auto" />
      <div style={{ minHeight: "60px" }} className="flex flex-column gap-4 overflow-hidden">
        <div className="text-center text-ellipsis">{profile?.displayName}</div>
        <div
          className="flex align-items-center justify-content-center gap-2 text-xl cursor-pointer"
          onClick={() => navigate(routes.logout.path)}
        >
          <i className="pi pi-sign-out"></i>
          <span>Logout</span>
        </div>
      </div>
    </div>
  );
}
