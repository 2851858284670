import { Chart } from "primereact/chart";

import { LoadReport } from "./LoadReport";
import { emptyPieChartPlugin } from "./EmptyPieChartPlugin";

interface LoadReportChartProps {
  value: LoadReport;
}

export default function LoadReportPieChart({ value }: LoadReportChartProps) {
  const documentStyle = getComputedStyle(document.documentElement);
  const typeChartData = {
    labels: ["Incidente", "Cereri"],
    datasets: [
      {
        data: [value.incidents, value.requests],
        backgroundColor: [documentStyle.getPropertyValue("--yellow-500"), documentStyle.getPropertyValue("--cyan-500")],
      },
    ],
  };
  const ticketsChartData = {
    labels: ["In lucru", "Inchise"],
    datasets: [
      {
        data: [value.openTickets, value.closedTickets],
        backgroundColor: [documentStyle.getPropertyValue("--pink-400"), documentStyle.getPropertyValue("--blue-400")],
      },
    ],
  };
  const slaChartData = {
    labels: ["Tichete in SLA", "Tichete in afara SLA"],
    datasets: [
      {
        data: [value.ticketsWithinSLA, value.ticketsOutsideSLA],
        backgroundColor: [documentStyle.getPropertyValue("--green-400"), documentStyle.getPropertyValue("--red-400")],
      },
    ],
  };
  const chartOptions = {
    plugins: {
      legend: {
        position: "bottom",
        labels: {
          generateLabels: (chart: any) => {
            const data = chart.data;
            return data.labels.map((label: string, i: number) => {
              const dataset = data.datasets[0];
              const value = dataset.data[i];
              return {
                text: `${label}: ${value}`,
                fillStyle: dataset.backgroundColor[i],
                strokeStyle: dataset.backgroundColor[i],
                hidden: isNaN(dataset.data[i]) || chart.getDatasetMeta(0).data[i].hidden,
                index: i,
              };
            });
          },
        },
      },
    },
  };
  const chartPlugins = [emptyPieChartPlugin];

  return (
    <div className="grid justify-content-center">
      <div className="col-12 md:col-4 xl:col-3">
        <Chart type="pie" options={chartOptions} plugins={chartPlugins} data={typeChartData}></Chart>
      </div>
      <div className="col-12 md:col-4 xl:col-3">
        <Chart type="pie" options={chartOptions} plugins={chartPlugins} data={ticketsChartData}></Chart>
      </div>
      <div className="col-12 md:col-4 xl:col-3">
        <Chart type="pie" options={chartOptions} plugins={chartPlugins} data={slaChartData}></Chart>
      </div>
    </div>
  );
}
