import { useContext } from "react";

import { LayoutContext } from "./LayoutContext";
import styles from "./Header.module.css";

export default function Header() {
  const { toggleSidebar } = useContext(LayoutContext);

  return (
    <div className={`${styles.layoutHeader} flex md:hidden align-items-center p-2 surface-section surface-border border-bottom-1 z-1`}>
      <i className="pi pi-align-justify p-4 cursor-pointer" onClick={toggleSidebar} />
    </div>
  );
}
