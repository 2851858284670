import { useEffect, useRef } from "react";

/**
 * Custom hook that skips the first render and runs the provided callback on subsequent renders.
 *
 * @param callback - The function to be executed after the first render.
 * @param dependencies - The dependency array that determines when the callback should be executed.
 *
 * @remarks
 * In strict mode, this hook will run twice due to React's double-invocation of certain lifecycle methods.
 */
export const useSkipFirstRender = (callback: () => void, dependencies: any[]) => {
  const firstRenderRef = useRef(true);
  useEffect(() => {
    if (firstRenderRef.current) {
      firstRenderRef.current = false;
      return;
    }
    callback();
  }, dependencies);
};
