export enum SystemSettingsApiKey {
  Server = "Smtp.Server",
  Port = "Smtp.Port",
  From = "Smtp.From",
  FromDisplayName = "Smtp.FromDisplayName",
  Username = "Smtp.Username",
  Password = "Smtp.Password",
  SecureConnection = "Smtp.SecureConnection",
}

export interface SystemSettings {
  server: string;
  port: number;
  from: string;
  fromDisplayName: string;
  username: string;
  password: string;
  secureConnection: boolean;
}
