import { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Card } from "primereact/card";

import { EmailTemplate } from "../emailTemplates/EmailTemplate";
import EmailTemplateForm from "./EmailTemplateForm";
import EmailTemplateTestForm from "./EmailTemplateTestForm";
import NoResultsFound from "../../common/components/noResultsFound/NoResultsFound";
import { fetchEmailTemplates, testEmailTemplate, updateEmailTemplate } from "../emailTemplates/emailTemplatesApi";
import DepartmentsDropdownCard from "../departments/DepartmentsDropdownCard";
import { LS_SELECTED_DEPARTMENT_ID } from "../../common/utils/common";

export default function EmailTemplatesPage() {
  const [selectedDepartmentId, setSelectedDepartmentId] = useState(localStorage.getItem(LS_SELECTED_DEPARTMENT_ID) || "");
  const [emailTemplates, setEmailTemplates] = useState<EmailTemplate[]>([]);
  const [selectedEmailTemplate, setSelectedEmailTemplate] = useState<EmailTemplate | undefined>(undefined);
  const [showEditEmailTemplateDialog, setShowEditEmailTemplateDialog] = useState(false);
  const [showTestEmailTemplateDialog, setShowTestEmailTemplateDialog] = useState(false);

  useEffect(() => {
    doFetchEmailTemplates();
  }, [selectedDepartmentId]);

  const doFetchEmailTemplates = async () => {
    if (selectedDepartmentId) {
      const emailTemplates = await fetchEmailTemplates(selectedDepartmentId);
      setEmailTemplates(emailTemplates);
    }
  };

  const closeEditDialog = () => {
    setSelectedEmailTemplate(undefined);
    setShowEditEmailTemplateDialog(false);
  };

  const closeTestDialog = () => {
    setSelectedEmailTemplate(undefined);
    setShowTestEmailTemplateDialog(false);
  };

  const onTest = (emailTemplate: EmailTemplate) => {
    setSelectedEmailTemplate(emailTemplate);
    setShowTestEmailTemplateDialog(true);
  };

  const onEdit = (emailTemplate: EmailTemplate) => {
    setSelectedEmailTemplate(emailTemplate);
    setShowEditEmailTemplateDialog(true);
  };

  const onSubmitTest = async (testEmail: string) => {
    await testEmailTemplate(selectedEmailTemplate!, testEmail);
    closeTestDialog();
  };

  const onSubmitEdit = async (emailTemplate: EmailTemplate) => {
    await updateEmailTemplate(emailTemplate);
    await doFetchEmailTemplates();
    closeEditDialog();
  };

  return (
    <div className="flex flex-column gap-2">
      <DepartmentsDropdownCard
        showAll
        value={selectedDepartmentId}
        onChange={(department) => setSelectedDepartmentId(department)}
      ></DepartmentsDropdownCard>
      <Card title="Sablonuri email">
        <DataTable size="small" value={emailTemplates} emptyMessage={<NoResultsFound />}>
          <Column field="emailType.name" header="Nume"></Column>
          <Column
            className="w-17rem"
            body={(rowData: EmailTemplate) => (
              <div className="flex align-items-center justify-content-end gap-2">
                <Button size="small" label="Testeaza" icon="pi pi-envelope" severity="warning" onClick={() => onTest(rowData)} />
                <Button size="small" label="Editeaza" icon="pi pi-pencil" onClick={() => onEdit(rowData)} />
              </div>
            )}
          ></Column>
        </DataTable>
        <Dialog className="w-full sm:w-6" header="Editeaza sablon" visible={showEditEmailTemplateDialog} onHide={() => closeEditDialog()}>
          <EmailTemplateForm emailTemplate={selectedEmailTemplate} onSubmit={onSubmitEdit} />
        </Dialog>
        <Dialog className="w-full sm:w-6" header="Testeaza email" visible={showTestEmailTemplateDialog} onHide={() => closeTestDialog()}>
          <EmailTemplateTestForm onSubmit={onSubmitTest} />
        </Dialog>
      </Card>
    </div>
  );
}
