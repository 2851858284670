import { ProgressBar } from "primereact/progressbar";

interface SlaProgressBarProps {
  slaPercent?: number;
  slaEndDatePretty?: string;
}

export default function SlaProgressBar({ slaPercent, slaEndDatePretty }: SlaProgressBarProps) {
  if (slaPercent == null || slaEndDatePretty == null) {
    return <span>Fara termen</span>;
  }

  let color = "";
  if (slaPercent > 75) {
    color = "warning";
  }
  if (slaPercent >= 100) {
    color = "danger";
  }

  return (
    <div className="flex flex-column gap-1">
      <ProgressBar className={color} value={slaPercent} title={`${slaPercent}%`} showValue={false}></ProgressBar>
      <span className="text-sm">{slaEndDatePretty}</span>
    </div>
  );
}
