import axios from "axios";
import { toast } from "react-toastify";

import { SystemSettings, SystemSettingsApiKey } from "./SystemSettings";

const SYSTEM_SETTINGS_API_URL = `${process.env.REACT_APP_API_BASE_URL}/systemsettings`;

export const fetchSystemSettings = async () => {
  const response = await axios.get(`${SYSTEM_SETTINGS_API_URL}`);
  const systemSettings = mapApiSystemSettings(response.data);
  return systemSettings;
};

export const updateSystemSettings = async (systemSettings: SystemSettings) => {
  const payload = [
    { key: SystemSettingsApiKey.Server, value: systemSettings.server },
    { key: SystemSettingsApiKey.Port, value: systemSettings.port },
    { key: SystemSettingsApiKey.From, value: systemSettings.from },
    { key: SystemSettingsApiKey.FromDisplayName, value: systemSettings.fromDisplayName },
    { key: SystemSettingsApiKey.Username, value: systemSettings.username },
    { key: SystemSettingsApiKey.Password, value: systemSettings.password },
    {
      key: SystemSettingsApiKey.SecureConnection,
      value: `${systemSettings.secureConnection}`,
    },
  ];
  await axios.post(`${SYSTEM_SETTINGS_API_URL}/bulk`, payload);
  toast.success("Setari salvate cu succes");
};

const mapApiSystemSettings = (apiSystemSettings: any): SystemSettings => {
  const systemSettings: SystemSettings = {
    server: "",
    port: 0,
    from: "",
    fromDisplayName: "",
    username: "",
    password: "",
    secureConnection: false,
  };

  for (const { key, value } of apiSystemSettings) {
    switch (key) {
      case SystemSettingsApiKey.Server:
        systemSettings.server = value;
        break;
      case SystemSettingsApiKey.Port:
        systemSettings.port = value;
        break;
      case SystemSettingsApiKey.From:
        systemSettings.from = value;
        break;
      case SystemSettingsApiKey.FromDisplayName:
        systemSettings.fromDisplayName = value;
        break;
      case SystemSettingsApiKey.Username:
        systemSettings.username = value;
        break;
      case SystemSettingsApiKey.Password:
        systemSettings.password = value;
        break;
      case SystemSettingsApiKey.SecureConnection:
        systemSettings.secureConnection = JSON.parse(value || "false");
        break;
    }
  }

  return systemSettings;
};
