import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";

import { routes } from "../routing/routes";

export default function NotFoundPage() {
  const navigate = useNavigate();

  return (
    <div className="mt-8 p-4 md:p-8 surface-section">
      <div className="flex flex-column md:flex-row justify-content-center align-items-center gap-6">
        <div className="text-center md:text-right">
          <div className="my-6 text-6xl font-bold">Te-ai pierdut?</div>
          <p className="mb-6 text-2xl">Pagina pe care vrei sa o accesezi nu exista</p>
          <Button label="Inapoi la pagina principala" icon="pi pi-home" outlined onClick={() => navigate(routes.dashboard.path)}></Button>
        </div>
        <div>
          <img className="w-full md:w-28rem" src="/images/bg-404.png" alt="Not found page" />
        </div>
      </div>
    </div>
  );
}
