export function EnvBar() {
  const isLocalEnv = window.location.hostname.startsWith("localhost");
  const isUatEnv = window.location.hostname.startsWith("uat-");
  const baseClassName = "fixed w-screen top-0 z-5 text-white text-center text-sm";
  const shouldRender = isLocalEnv || isUatEnv;

  return shouldRender ? (
    <div className={`${isLocalEnv ? "bg-yellow-500" : ""} ${isUatEnv ? "bg-red-500" : ""} ${baseClassName}}`}>
      {isLocalEnv && "LOCAL ENVIRONMENT"}
      {isUatEnv && "UAT ENVIRONMENT"}
    </div>
  ) : null;
}
