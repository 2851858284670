export enum NoteType {
  Internal = 0,
  External = 1,
  System = 2,
}

export const noteTypes = [
  {
    id: NoteType.Internal,
    label: "Interna",
  },
  {
    id: NoteType.External,
    label: "Externa",
  },
  {
    id: NoteType.System,
    label: "Sistem",
  },
];
