import { useState } from "react";
import { useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { Card } from "primereact/card";
import { InputSwitch } from "primereact/inputswitch";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";

import UserForm from "../users/UserForm";
import { selectProfile, updateOutOfOffice } from "../auth/authSlice";
import { User } from "../users/User";
import { convertDateStringToDate, convertDateToDateString } from "../../common/utils/dateHelper";
import { useAppDispatch } from "../../common/hooks/useStore";
import UsersDropdown from "../users/UsersDropdown";
import FormGroup from "../../common/components/form/FormGroup";
import Label from "../../common/components/form/Label";
import { UserRoleEnum } from "../userRoles/UserRole";
import { confirmDialog } from "primereact/confirmdialog";

export default function MyProfile() {
  const dispatch = useAppDispatch();
  const profile = useSelector(selectProfile);
  const [isOutOfOfficeEnabled, setIsOutOfOfficeEnabled] = useState(profile?.outOfOfficeEnabled || false);
  const [isLoading, setIsLoading] = useState(false);
  const { control, reset, handleSubmit, getValues } = useForm<User>({ defaultValues: profile });

  const onOutOfOfficeEnabledChange = async (isEnabled: boolean) => {
    setIsOutOfOfficeEnabled(isEnabled);
    if (!isEnabled) {
      reset({
        outOfOfficeEnabled: isEnabled,
        outOfOfficeStart: undefined,
        outOfOfficeEnd: undefined,
        outOfOfficeReplacementId: undefined,
      });
      await onSubmit(getValues());
    }
  };

  const onSubmit = async (data: User) => {
    if (!data.outOfOfficeEnabled) {
      doUpdateOutOfOffice(data);
    } else {
      confirmDialog({
        header: "Confirmare concediu",
        icon: "pi pi-exclamation-triangle",
        message: (
          <div className="text-xl">
            Esti sigur ca vrei sa iti setezi concediul?
            <br />
            Toate tichetele tale active vor fi asignate automat catre inlocuitor in perioada concediului.
          </div>
        ),
        acceptLabel: "Da",
        rejectLabel: "Nu",
        accept: () => doUpdateOutOfOffice(data),
      });
    }
  };

  const doUpdateOutOfOffice = async (data: User) => {
    setIsLoading(true);
    await dispatch(
      updateOutOfOffice({
        ...data,
        id: profile.id,
      }),
    );
    setIsLoading(false);
  };

  return (
    <>
      <Card className="card-pb-0 mb-2" title="Configurare concediu">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="grid">
            <div className="flex flex-row lg:flex-column align-items-center gap-2 col-12 lg:col-1">
              <Label>Activ</Label>
              <Controller
                name="outOfOfficeEnabled"
                control={control}
                render={({ field }) => (
                  <InputSwitch
                    checked={field.value}
                    onChange={(e) => {
                      onOutOfOfficeEnabledChange(e.value);
                      field.onChange(e.value);
                    }}
                  />
                )}
              ></Controller>
            </div>
            <FormGroup className="col-12 lg:col-3" label="Concediu de la" size="small">
              <Controller
                name="outOfOfficeStart"
                control={control}
                render={({ field }) => (
                  <Calendar
                    showIcon
                    dateFormat="dd/mm/yy"
                    value={convertDateStringToDate(field.value)}
                    onChange={(e) => field.onChange(convertDateToDateString(e.target.value ?? undefined))}
                    disabled={!isOutOfOfficeEnabled}
                  />
                )}
              ></Controller>
            </FormGroup>
            <FormGroup className="col-12 lg:col-3" label="Concediu pana la" size="small">
              <Controller
                name="outOfOfficeEnd"
                control={control}
                render={({ field }) => (
                  <Calendar
                    showIcon
                    dateFormat="dd/mm/yy"
                    value={convertDateStringToDate(field.value)}
                    onChange={(e) => field.onChange(convertDateToDateString(e.target.value ?? undefined))}
                    disabled={!isOutOfOfficeEnabled}
                  />
                )}
              ></Controller>
            </FormGroup>
            <FormGroup className="col-12 lg:col-5" label="Inlocuitor" size="small">
              <Controller
                name="outOfOfficeReplacementId"
                control={control}
                render={({ field }) => (
                  <UsersDropdown
                    teamIds={profile.teamIds}
                    role={UserRoleEnum.Agent}
                    value={field.value}
                    onChange={(e) => field.onChange(e)}
                    disabled={!isOutOfOfficeEnabled}
                  />
                )}
              ></Controller>
            </FormGroup>
            <div className="col-12">
              <Button type="submit" className="block ml-auto" label="Salveaza" icon="pi pi-save" loading={isLoading} disabled={isLoading} />
            </div>
          </div>
        </form>
      </Card>
      <Card title="Datele mele">
        <UserForm readOnly user={profile} />
      </Card>
    </>
  );
}
