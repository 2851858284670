import { useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";

import FormGroup from "../../common/components/form/FormGroup";
import { EmailTemplateTest } from "../emailTemplates/EmailTemplate";
import { selectProfile } from "../auth/authSlice";

interface EmailTemplateTestFormProps {
  onSubmit: (testEmail: string) => Promise<void>;
}

export default function EmailTemplateTestForm(props: EmailTemplateTestFormProps) {
  const profile = useSelector(selectProfile);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<EmailTemplateTest>({
    defaultValues: { testEmail: profile.emailAddress ?? "" },
  });
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (data: EmailTemplateTest) => {
    setIsLoading(true);
    await props.onSubmit(data.testEmail);
    setIsLoading(false);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormGroup label="Email de test" errorMessage={errors.testEmail?.message}>
        <InputText type="email" {...register("testEmail", { required: "Emailul de test este obligatoriu" })} />
      </FormGroup>
      <Button type="submit" className="block ml-auto" label="Trimite" icon="pi pi-send" loading={isLoading} disabled={isLoading} />
    </form>
  );
}
