import { PropsWithChildren } from "react";
import { useSelector } from "react-redux";

import { Privilege } from "../../features/auth/Privilege";
import { selectAuthUser } from "../../features/auth/authSlice";
import { canAccess } from "../../features/auth/utils";

interface ProtectedChildrenProps extends PropsWithChildren {
  requiredPrivilege: Privilege;
}

export default function ProtectedChildren({ requiredPrivilege, children }: ProtectedChildrenProps) {
  const authUser = useSelector(selectAuthUser);

  return <>{canAccess(requiredPrivilege, authUser?.privilege) && children}</>;
}
