import { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Card } from "primereact/card";
import { confirmDialog } from "primereact/confirmdialog";

import TeamForm from "./TeamsForm";
import DepartmentsDropdownCard from "../departments/DepartmentsDropdownCard";
import NoResultsFound from "../../common/components/noResultsFound/NoResultsFound";
import TeamUsers from "./TeamUsers";
import { Team } from "../teams/Team";
import { createTeam, deleteTeam, fetchTeamsByDepartment, updateTeam } from "../teams/teamsApi";
import { LS_SELECTED_DEPARTMENT_ID } from "../../common/utils/common";

export default function TeamsPage() {
  const [allTeams, setAllTeams] = useState<Team[]>([]);
  const [selectedDepartmentId, setSelectedDepartmentId] = useState(localStorage.getItem(LS_SELECTED_DEPARTMENT_ID) || "");
  const [selectedTeam, setSelectedTeam] = useState<Team>();
  const [showTeamDialog, setShowTeamDialog] = useState(false);
  const [expandedRows, setExpandedRows] = useState<any>([]);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    doFetchTeamsByDepartment();
  }, [selectedDepartmentId]);

  const closeDialog = () => {
    setSelectedTeam(undefined);
    setShowTeamDialog(false);
  };

  const onCreate = () => {
    setSelectedTeam(undefined);
    setShowTeamDialog(true);
    setIsEditing(false);
  };

  const onEdit = (team: Team) => {
    setSelectedTeam(team);
    setShowTeamDialog(true);
    setIsEditing(true);
  };

  const onDelete = (team: Team) => {
    confirmDialog({
      header: "Confirmare stergere",
      icon: "pi pi-exclamation-triangle",
      message: (
        <div className="text-xl">
          Esti sigur ca vrei sa stergi grupa <span className="font-bold">{team.name}</span>?
        </div>
      ),
      acceptLabel: "Da",
      rejectLabel: "Nu",
      accept: async () => {
        await deleteTeam(team);
        await doFetchTeamsByDepartment();
      },
    });
  };

  const onSubmit = async (team: Team) => {
    team.departmentId = selectedDepartmentId;
    if (isEditing) {
      await updateTeam(team);
    } else {
      await createTeam(team);
    }
    await doFetchTeamsByDepartment();
    closeDialog();
  };

  const doFetchTeamsByDepartment = async () => {
    if (selectedDepartmentId) {
      const teams = await fetchTeamsByDepartment(selectedDepartmentId);
      setAllTeams(teams);
    }
  };

  return (
    <div className="flex flex-column gap-2">
      <DepartmentsDropdownCard
        showAll
        value={selectedDepartmentId}
        onChange={(department) => setSelectedDepartmentId(department)}
      ></DepartmentsDropdownCard>
      <Card
        title={
          <div className="flex align-items-center justify-content-between">
            <div className="text-2xl font-bold">Grupe</div>
            <Button label="Adauga" icon="pi pi-plus" onClick={() => onCreate()} />
          </div>
        }
      >
        <DataTable
          size="small"
          value={allTeams}
          expandedRows={expandedRows}
          emptyMessage={<NoResultsFound />}
          rowExpansionTemplate={(team) => <TeamUsers className="p-3 surface-ground" teamId={team.id}></TeamUsers>}
          onRowToggle={(e) => setExpandedRows(e.data)}
        >
          <Column expander className="w-2rem"></Column>
          <Column field="name" header="Nume"></Column>
          <Column
            className="w-17rem"
            body={(rowData: Team) => (
              <div className="flex align-items-center justify-content-end gap-2">
                <Button size="small" label="Editeaza" icon="pi pi-pencil" onClick={() => onEdit(rowData)} />
                <Button size="small" label="Sterge" severity="danger" icon="pi pi-trash" onClick={() => onDelete(rowData)} />
              </div>
            )}
          ></Column>
        </DataTable>
        <Dialog
          className="w-full sm:w-6"
          header={selectedTeam ? "Editeaza grupa" : "Adauga grupa"}
          visible={showTeamDialog}
          onHide={() => closeDialog()}
        >
          <TeamForm team={selectedTeam} onSubmit={onSubmit} />
        </Dialog>
      </Card>
    </div>
  );
}
