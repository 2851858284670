import { PropsWithChildren, createContext, useState } from "react";

const standardWidth = "1024px";
const fullWidth = "100%";

export const LayoutContext = createContext({
  sidebarIsOpen: false,
  contentWidth: standardWidth,
  toggleSidebar: () => {},
  closeSidebar: () => {},
  openSidebar: () => {},
  setFullContentWidth: () => {},
  setStandardContentWidth: () => {},
});

export const LayoutContextProvider = ({ children }: PropsWithChildren) => {
  const [sidebarIsOpen, setSidebarIsOpen] = useState(false);
  const [contentWidth, setContentWidth] = useState(standardWidth);

  const toggleSidebar = () => {
    setSidebarIsOpen(!sidebarIsOpen);
  };

  const closeSidebar = () => {
    setSidebarIsOpen(false);
  };

  const openSidebar = () => {
    setSidebarIsOpen(true);
  };

  const setFullContentWidth = () => {
    setContentWidth(fullWidth);
  };

  const setStandardContentWidth = () => {
    setContentWidth(standardWidth);
  };

  return (
    <LayoutContext.Provider
      value={{
        sidebarIsOpen,
        contentWidth,
        toggleSidebar,
        closeSidebar,
        openSidebar,
        setFullContentWidth,
        setStandardContentWidth,
      }}
    >
      {children}
    </LayoutContext.Provider>
  );
};
