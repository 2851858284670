import { Ref, forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { Paginator } from "primereact/paginator";

import NoteDetails from "../note/NoteDetails";
import {
  defaultPageSize,
  defaultPaginationOptions,
  emptyPaginationDetails,
  getPaginationFirst,
  getPaginationOptionsFromDataTable,
} from "../../common/api/paginationHelper";
import { Note } from "../note/Note";
import { searchAllNotes } from "../note/notesApi";
import { NoteType } from "../note/NoteType";

interface TicketNotesProps {
  ticketId: string;
  noteTypes: NoteType[];
}

export interface TicketNotesRef {
  refreshNotes: () => void;
}

export const TicketNotes = forwardRef(({ ticketId, noteTypes }: TicketNotesProps, ref: Ref<TicketNotesRef>) => {
  const [pagination, setPagination] = useState(emptyPaginationDetails);
  const [first, setFirst] = useState(getPaginationFirst(pagination.pageNumber, defaultPageSize));
  const [rows, setRows] = useState(defaultPageSize);
  const [notes, setNotes] = useState<Note[]>([]);

  useImperativeHandle(ref, () => ({
    refreshNotes() {
      doSearchNotes();
    },
  }));

  useEffect(() => {
    doSearchNotes();
  }, [first, rows, ticketId]);

  const doSearchNotes = async () => {
    if (!ticketId) {
      return;
    }

    const notesResponse = await searchAllNotes({
      ticketId,
      noteTypes,
      ...getPaginationOptionsFromDataTable(first, rows),
    });
    setNotes(notesResponse.notes);
    setPagination(notesResponse.pagination);
  };

  return (
    <>
      {notes.map((note) => (
        <NoteDetails key={note.id} className="mb-2" note={note}></NoteDetails>
      ))}
      <Paginator
        first={first}
        rows={rows}
        rowsPerPageOptions={defaultPaginationOptions}
        totalRecords={pagination.totalCount}
        onPageChange={(e) => {
          setFirst(e.first);
          setRows(e.rows);
        }}
      />
    </>
  );
});
