import axios from "axios";
import { toast } from "react-toastify";

import { NonWorkingDay } from "./NonWorkingDay";

const NON_WORKING_DAYS_API_URL = `${process.env.REACT_APP_API_BASE_URL}/nonWorkingDays`;

export const fetchNonWorkingDays = async (departmentId: string) => {
  try {
    const response = await axios.get(`${NON_WORKING_DAYS_API_URL}/department/${departmentId}`);
    const nonWorkingDays: NonWorkingDay[] = response.data.map((nonWorkingDay: any) => ({
      id: `${nonWorkingDay.id}`,
      departmentId: `${nonWorkingDay.departmentId}`,
    }));
    return nonWorkingDays;
  } catch (err) {
    return [];
  }
};

export const createNonWorkingDay = async (newNonWorkingDay: NonWorkingDay) => {
  const payload = {
    ...newNonWorkingDay,
    departmentId: parseInt(newNonWorkingDay.departmentId),
  };
  await axios.post(NON_WORKING_DAYS_API_URL, payload);
  toast.success("Ziua libera a fost adaugata cu succes!");
};

export const deleteNonWorkingDay = async (nonWorkingDay: NonWorkingDay) => {
  await axios.delete(`${NON_WORKING_DAYS_API_URL}/department/${nonWorkingDay.departmentId}/date/${nonWorkingDay.id}`);
  toast.success("Ziua libera a fost stearsa cu succes!");
};
