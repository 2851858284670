import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { confirmDialog } from "primereact/confirmdialog";

import { Ticket } from "./Ticket";

export function useReplacementTicketWarning(navigatePath: string, ticket?: Ticket) {
  const navigate = useNavigate();

  useEffect(() => {
    if (!ticket?.replacedById) {
      return;
    }

    confirmDialog({
      header: "Tichet unit",
      icon: "pi pi-exclamation-triangle",
      message: (
        <div className="text-xl">
          Tichetul <span className="font-bold">{ticket.prettyId}</span> a fost unit cu tichetul
          <span className="font-bold"> {ticket.prettyReplacedById}</span>
        </div>
      ),
      closable: false,
      acceptLabel: "Navigheaza",
      rejectLabel: "Inapoi",
      reject: () => navigate(-1),
      accept: () => navigate(`${navigatePath}/${ticket.replacedById}`),
    });
  }, [ticket]);
}
