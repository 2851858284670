import { Chart } from "primereact/chart";

import { LoadReport } from "./LoadReport";

interface LoadReportBarChartProps {
  teamLoadReport: LoadReport;
  agentLoadReport: LoadReport;
}

export default function LoadReportBarChart({ teamLoadReport, agentLoadReport }: LoadReportBarChartProps) {
  const chartData = {
    labels: ["Incidente", "Cereri", "In lucru", "Inchise", "In SLA", "In afara SLA"],
    datasets: [
      {
        label: "Grupa",
        data: [
          teamLoadReport.incidents,
          teamLoadReport.requests,
          teamLoadReport.openTickets,
          teamLoadReport.closedTickets,
          teamLoadReport.ticketsWithinSLA,
          teamLoadReport.ticketsOutsideSLA,
        ],
      },
      {
        label: "Agent",
        data: [
          agentLoadReport.incidents,
          agentLoadReport.requests,
          agentLoadReport.openTickets,
          agentLoadReport.closedTickets,
          agentLoadReport.ticketsWithinSLA,
          agentLoadReport.ticketsOutsideSLA,
        ],
      },
    ],
  };
  const chartOptions = {
    plugins: {
      legend: {
        position: "bottom",
      },
    },
  };

  return <Chart type="bar" options={chartOptions} data={chartData}></Chart>;
}
