import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Editor, EditorTextChangeEvent } from "primereact/editor";
import { Chips } from "primereact/chips";
import { InputSwitch } from "primereact/inputswitch";

import { Question } from "../questions/Question";
import QuillBar from "../../common/components/quillBar/QuillBar";
import FormGroup from "../../common/components/form/FormGroup";

interface QuestionFormProps {
  question?: Question;
  onSubmit: (question: Question) => Promise<void>;
}

export default function QuestionForm(props: QuestionFormProps) {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<Question>({
    defaultValues: {
      ...props.question,
      tags: props.question?.tags || [],
    },
  });
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (data: Question) => {
    setIsLoading(true);
    await props.onSubmit(data);
    setIsLoading(false);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormGroup inline label="Activa">
        <Controller
          name="isPublished"
          control={control}
          render={({ field }) => <InputSwitch checked={field.value} onChange={(e) => field.onChange(e.value)} />}
        ></Controller>
      </FormGroup>
      <FormGroup label="Intrebare *" errorMessage={errors.questionText?.message}>
        <InputText {...register("questionText", { required: "Intrebarea este obligatorie" })} />
      </FormGroup>
      <FormGroup label="Raspuns *" errorMessage={errors.answerText?.message}>
        <Controller
          name="answerText"
          control={control}
          rules={{
            required: "Raspunsul este obligatoriu",
            minLength: { value: 3, message: "Raspunsul trebuie să aibă cel puțin 3 caractere" },
            maxLength: { value: 4000, message: "Raspunsul nu poate avea mai mult de 4000 de caractere" },
          }}
          render={({ field }) => (
            <Editor
              name="answerText"
              value={field.value}
              headerTemplate={<QuillBar />}
              onTextChange={(e: EditorTextChangeEvent) => field.onChange(e.htmlValue)}
            />
          )}
        ></Controller>
      </FormGroup>
      <FormGroup label="Cuvinte cheie">
        <Controller
          name="tags"
          control={control}
          render={({ field }) => <Chips className="p-fluid" value={field.value} onChange={(e) => field.onChange(e.value)} />}
        ></Controller>
      </FormGroup>
      <Button type="submit" className="block ml-auto" label="Salveaza" icon="pi pi-save" loading={isLoading} disabled={isLoading} />
    </form>
  );
}
