interface LoadReportPieChartTitleProps {
  title: string;
  totalTickets: number;
}

export default function LoadReportPieChartTitle({ title, totalTickets }: LoadReportPieChartTitleProps) {
  return (
    <div className="flex align-items-center justify-content-between">
      <div className="text-2xl font-bold">{title}</div>
      <div className="text-xl">Total tichete: {totalTickets}</div>
    </div>
  );
}
