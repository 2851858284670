import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

import NoResultsFound from "../../common/components/noResultsFound/NoResultsFound";
import {
  defaultPageSize,
  defaultPaginationOptions,
  emptyPaginationDetails,
  getPaginationFirst,
  getPaginationOptionsFromDataTable,
} from "../../common/api/paginationHelper";
import { routes } from "../../common/routing/routes";
import { ReportsFilters } from "./ReportsFilters";
import { TicketReport } from "./TicketReport";
import { fetchTicketsReport } from "./reportsApi";

interface TicketReportTableProps {
  filters: ReportsFilters;
}

export default function TicketsReportTable({ filters }: TicketReportTableProps) {
  const navigate = useNavigate();
  const [ticketsReport, setTicketsReport] = useState<TicketReport[]>();
  const [pagination, setPagination] = useState(emptyPaginationDetails);
  const [first, setFirst] = useState(() => getPaginationFirst(pagination.pageNumber, defaultPageSize));
  const [rows, setRows] = useState(defaultPageSize);

  useEffect(() => {
    doFetchTicketsReport();
  }, [first, rows, filters]);

  const doFetchTicketsReport = async () => {
    const response = await fetchTicketsReport(filters, getPaginationOptionsFromDataTable(first, rows));
    setPagination(response.pagination);
    setTicketsReport(response.ticketsReport);
  };

  return (
    <DataTable
      size="small"
      value={ticketsReport}
      lazy
      paginator
      first={first}
      rows={rows}
      rowsPerPageOptions={defaultPaginationOptions}
      totalRecords={pagination.totalCount}
      emptyMessage={<NoResultsFound />}
      rowClassName={(rowData) => (rowData.slaExceeded ? "text-red-400 cursor-pointer" : "cursor-pointer")}
      onRowClick={(e) => navigate(`${routes.agentTicketDetails.navigatePath}/${e.data.id}`)}
      onPage={(e) => {
        setFirst(e.first);
        setRows(e.rows);
      }}
    >
      <Column header="Id" field="prettyId"></Column>
      <Column header="Tip" field="type"></Column>
      <Column header="Prioritate" field="urgency"></Column>
      <Column header="Status" field="status"></Column>
      <Column header="Categorie" field="categoryName"></Column>
      <Column header="Locatie" field="location"></Column>
      <Column header="Grupa" field="teamName"></Column>
      <Column header="Agent" field="agentName"></Column>
      <Column header="Utilizator" field="userName"></Column>
      <Column header="Data rezolvare" field="resolvedDate"></Column>
    </DataTable>
  );
}
