import { configureStore } from "@reduxjs/toolkit";

import authReducer from "../../features/auth/authSlice";
import urgenciesReducer from "../../features/urgencies/urgenciesSlice";
import ticketPrioritiesReducer from "../../features/ticketPriorities/ticketPrioritiesSlice";
import daysOfWeekReducer from "../../features/daysOfWeek/daysOfWeekSlice";
import departmentsReducer from "../../features/departments/departmentsSlice";
import userRolesReducer from "../../features/userRoles/userRolesSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    urgencies: urgenciesReducer,
    ticketPriorities: ticketPrioritiesReducer,
    daysOfWeek: daysOfWeekReducer,
    departments: departmentsReducer,
    userRoles: userRolesReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
