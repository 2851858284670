import { Card } from "primereact/card";

import DepartmentsDropdown from "./DepartmentsDropdown";
import { PropsWithClassName } from "../../common/types/PropsWithClassName";
import { LS_SELECTED_DEPARTMENT_ID } from "../../common/utils/common";

interface DepartmentsDropdownCardProps extends PropsWithClassName {
  showAll?: boolean;
  value?: string;
  onChange: (departmentId: string) => void;
}

export default function DepartmentsDropdownCard({ className, showAll, value, onChange }: DepartmentsDropdownCardProps) {
  const doChange = (departmentId: string) => {
    localStorage.setItem(LS_SELECTED_DEPARTMENT_ID, departmentId);
    onChange(departmentId);
  };

  return (
    <Card className={`${className}`} title="Departament">
      <DepartmentsDropdown className="w-full" showAll={showAll} value={value} onChange={doChange}></DepartmentsDropdown>
    </Card>
  );
}
