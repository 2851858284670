import { PropsWithChildren } from "react";

import { PropsWithClassName } from "../../types/PropsWithClassName";

export default function Label({ style, className, children }: PropsWithChildren & PropsWithClassName) {
  return (
    <label style={style} className={`${className} text-lg font-medium`}>
      {children}
    </label>
  );
}
