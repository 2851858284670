import { useSelector } from "react-redux";
import { Dropdown } from "primereact/dropdown";

import { selectAllUrgencies } from "./urgenciesSlice";

interface UrgencyDropdownProps {
  value?: string;
  showClear?: boolean;
  onChange: (urgencyId?: string) => void;
}

export default function UrgencyDropdown({ value, showClear, onChange }: UrgencyDropdownProps) {
  const urgencies = useSelector(selectAllUrgencies);
  const selectedUrgency = urgencies.find((urgency) => urgency.id === value);

  return (
    <Dropdown
      optionLabel="name"
      options={urgencies}
      value={selectedUrgency}
      showClear={showClear}
      onChange={(e) => onChange(e.value?.id)}
    />
  );
}
