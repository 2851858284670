import axios from "axios";
import { toast } from "react-toastify";

import { mapApiUser } from "../users/usersApi";
import { Team } from "./Team";

export const TEAMS_API_URL = `${process.env.REACT_APP_API_BASE_URL}/teams`;

export const fetchTeamsByDepartment = async (departmentId: string) => {
  try {
    const response = await axios.get(`${TEAMS_API_URL}/by-department/${departmentId}`);
    const teams: Team[] = response.data.map(mapApiTeam);
    return teams;
  } catch (err) {
    return [];
  }
};

export const createTeam = async (team: Team) => {
  const payload = {
    ...team,
    departmentId: parseInt(team.departmentId),
  };
  const response = await axios.post(TEAMS_API_URL, payload);
  const newTeam = mapApiTeam(response.data);
  toast.success("Grupa a fost adaugata cu succes!");
  return newTeam;
};

export const updateTeam = async (team: Team) => {
  const payload = {
    ...team,
    id: parseInt(team.id),
    departmentId: parseInt(team.departmentId),
  };
  await axios.put(TEAMS_API_URL, payload);
  toast.success("Grupa a fost actualizata cu succes!");
};

export const deleteTeam = async (team: Team) => {
  await axios.delete(`${TEAMS_API_URL}/${team.id}`);
  toast.success("Grupa a fost stearsa cu succes!");
};

export const addUserToTeams = async (userId: string, teamIds: string[]) => {
  const payload = {
    userId,
    teamIds: teamIds.map((id) => parseInt(id)),
  };
  await axios.put(`${TEAMS_API_URL}/set-teams`, payload);
};

export const mapApiTeam = (apiTeam: any) => ({
  id: `${apiTeam.id}`,
  departmentId: `${apiTeam.departmentId}`,
  name: apiTeam.name,
  users: (apiTeam.users || []).map(mapApiUser),
});
