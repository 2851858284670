import { useEffect, useRef, useState } from "react";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import { InputText } from "primereact/inputtext";
import { OverlayPanel } from "primereact/overlaypanel";
import { PrimeIcons } from "primereact/api";

import NoResultsFound from "../noResultsFound/NoResultsFound";

const iconExceptions: string[] = [
  "pi-arrow-right-arrow-left",
  "pi pi-sort-alpha-alt-down",
  "pi pi-sort-alpha-alt-up",
  "pi pi-sort-slash",
  "pi pi-sort-numeric-alt-down",
  "pi pi-sort-numeric-alt-up",
];

interface IconPickerInterface {
  value?: string;
  onChange: (value: string) => void;
}

export default function IconPicker({ value, onChange }: IconPickerInterface) {
  const overlayPanelRef = useRef<OverlayPanel>(null);
  const [searchText, setSearchText] = useState("");
  const [selectedIcon, setSelectedIcon] = useState(value || PrimeIcons.FILE.replace("pi", ""));
  const icons: string[] = Object.values(PrimeIcons).filter((icon) => !iconExceptions.includes(icon));
  const filteredIcons = icons.filter((icon) => icon.includes(searchText.toLowerCase()));

  useEffect(() => {
    if (!value) {
      onChange(PrimeIcons.FILE.replace("pi ", ""));
    }
  }, [value]);

  const onIconChange = (piIcon: string) => {
    const icon = piIcon.replace("pi ", "");
    overlayPanelRef.current?.hide();
    setSearchText("");
    setSelectedIcon(icon);
    onChange(icon);
  };

  return (
    <>
      <div className="flex align-items-center gap-2">
        <Button
          type="button"
          rounded
          outlined
          severity="secondary"
          icon={`pi ${selectedIcon}`}
          onClick={(e) => overlayPanelRef.current?.toggle(e)}
        />
        <span>{selectedIcon.replace("pi-", "")}</span>
      </div>
      <OverlayPanel className="w-10 md:w-5 text-center shadow-2" ref={overlayPanelRef}>
        <InputText className="w-full" placeholder="Cautare" value={searchText} onChange={(e) => setSearchText(e.target.value)} />
        <Divider />
        <div className="grid justify-content-center max-h-24rem overflow-y-scroll">
          {filteredIcons.length ? (
            filteredIcons.map((icon) => (
              <div className="col-6 sm:col-4 lg:col-3" key={icon}>
                <Button size="large" text severity="secondary" icon={icon} onClick={() => onIconChange(icon)} />
                <div className="text-ellipsis">{icon.replace("pi pi-", "")}</div>
              </div>
            ))
          ) : (
            <NoResultsFound />
          )}
        </div>
      </OverlayPanel>
    </>
  );
}
