import axios from "axios";

import { LS_AUTH_TOKEN_KEY, LS_REFRESH_TOKEN_KEY } from "../../common/utils/common";

export const AUTH_API_URL = `${process.env.REACT_APP_API_BASE_URL}/users`;

export const refreshToken = async () => {
  const payload = {
    token: localStorage.getItem(LS_AUTH_TOKEN_KEY),
    refreshToken: localStorage.getItem(LS_REFRESH_TOKEN_KEY),
  };
  const response = await axios.post(`${AUTH_API_URL}/refreshtoken`, payload);
  const token = response.data.token;
  const refreshToken = response.data.refreshToken;

  localStorage.setItem(LS_AUTH_TOKEN_KEY, token);
  localStorage.setItem(LS_REFRESH_TOKEN_KEY, refreshToken);

  return {
    token,
    refreshToken,
  };
};
