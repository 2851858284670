import { PropsWithChildren } from "react";
import { useSelector } from "react-redux";

import { selectAuthUser } from "../../../features/auth/authSlice";
import { canAccess } from "../../../features/auth/utils";
import { PropsWithClassName } from "../../types/PropsWithClassName";

interface SidebarCategoryProps extends PropsWithChildren, PropsWithClassName {
  name: string;
  requiredPrivilege?: number;
  onClick?: () => void;
}

export default function SidebarCategory({ className, children, name, requiredPrivilege, onClick }: SidebarCategoryProps) {
  const authUser = useSelector(selectAuthUser);

  if (!canAccess(requiredPrivilege, authUser?.privilege)) {
    return null;
  }

  return (
    <div className={`${className} flex flex-column gap-2 px-2`}>
      <div className="text-xl font-bold" onClick={onClick}>
        {name}
      </div>
      <div>{children}</div>
    </div>
  );
}
