import { ReactNode } from "react";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";

import styles from "./SidebarNavLink.module.css";
import { selectAuthUser } from "../../../features/auth/authSlice";
import { routes } from "../../routing/routes";
import { canAccess } from "../../../features/auth/utils";

interface SidebarNavLinkProps {
  path: string;
  icon?: string;
  text?: ReactNode | string;
}

export default function SidebarNavLink({ path, icon, text }: SidebarNavLinkProps) {
  const authUser = useSelector(selectAuthUser);

  const requiredPrivilege = Object.values(routes).find((route) => route.path === path)!.requiredPrivilege;
  if (!canAccess(requiredPrivilege, authUser?.privilege)) {
    return null;
  }

  return (
    <NavLink
      className={({ isActive }) =>
        `${styles.link} ${isActive ? styles.linkActive : "text-color"} flex align-items-center mt-2 pl-2 py-1 text-lg no-underline`
      }
      to={path}
    >
      {icon && <i className={`pi ${icon} mr-2`} />}
      {text}
    </NavLink>
  );
}
