import { Avatar } from "primereact/avatar";

import { User } from "./User";

interface UserAvatarProps {
  user: User;
}

export default function UserAvatar({ user }: UserAvatarProps) {
  const initials = `${user.firstName[0]}${user.lastName[0]}`;

  return <Avatar label={initials} shape="circle"></Avatar>;
}
