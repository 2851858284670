import axios from "axios";
import { toast } from "react-toastify";

import { Question, SearchQuestionsFilter } from "./Question";
import { emptyPaginationDetails, mapApiPaginationDetails, mapToApiPagination } from "../../common/api/paginationHelper";

const QUESTIONS_API_URL = `${process.env.REACT_APP_API_BASE_URL}/questions`;

export const fetchQuestions = async (departmentId: string) => {
  try {
    const response = await axios.get(`${QUESTIONS_API_URL}/department/${departmentId}`);
    const questions: Question[] = response.data.map(mapApiQuestion);
    return questions;
  } catch (err) {
    return [];
  }
};

export const searchPublishedQuestions = async (filter: SearchQuestionsFilter) => {
  return doSearch(filter, `${QUESTIONS_API_URL}/search-published`);
};

export const searchAllQuestions = async (filter: SearchQuestionsFilter) => {
  return doSearch(filter, `${QUESTIONS_API_URL}/search`);
};

export const createQuestion = async (question: Question) => {
  const payload = {
    ...question,
    departmentId: parseInt(question.departmentId),
  };
  const response = await axios.post(QUESTIONS_API_URL, payload);
  const newQuestion = mapApiQuestion(response.data);
  toast.success("Intrebarea a fost adaugata cu succes!");
  return newQuestion;
};

export const updateQuestion = async (question: Question) => {
  const payload = {
    ...question,
    id: parseInt(question.id),
    departmentId: parseInt(question.departmentId),
  };
  await axios.put(QUESTIONS_API_URL, payload);
  toast.success("Intrebarea a fost actualizata cu succes!");
};

export const deleteQuestion = async (question: Question) => {
  await axios.delete(`${QUESTIONS_API_URL}/${question.id}`);
  toast.success("Intrebarea a fost stearsa cu succes!");
};

export const likeQuestion = async (question: Question) => {
  await axios.put(`${QUESTIONS_API_URL}/like`, question.id, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  toast.success("Intrebarea a fost votata cu succes!");
};

export const dislikeQuestion = async (question: Question) => {
  await axios.put(`${QUESTIONS_API_URL}/dislike`, question.id, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  toast.success("Intrebarea a fost votata cu succes!");
};

export const viewQuestion = async (question: Question) => {
  await axios.put(`${QUESTIONS_API_URL}/view`, question.id, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const mapApiQuestion = (apiQuestion: any): Question => ({
  id: `${apiQuestion.id}`,
  departmentId: `${apiQuestion.departmentId}`,
  questionText: apiQuestion.questionText,
  answerText: apiQuestion.answerText,
  views: apiQuestion.views || 0,
  likes: apiQuestion.likes || 0,
  dislikes: apiQuestion.dislikes || 0,
  absoluteLikes: apiQuestion.likes || 0 - apiQuestion.dislikes || 0,
  tags: apiQuestion.tags,
  createdBy: apiQuestion.createdBy,
  isPublished: apiQuestion.isPublished,
});

const doSearch = async (filter: SearchQuestionsFilter, url: string) => {
  try {
    const payload = {
      departmentId: parseFloat(filter.departmentId),
      searchTerm: filter.searchTerm,
      ...mapToApiPagination(filter),
    };
    const response = await axios.post(url, payload);
    const pagination = mapApiPaginationDetails(response.data);
    const questions: Question[] = response.data.items.map(mapApiQuestion);
    return {
      pagination,
      questions,
    };
  } catch (err) {
    return {
      pagination: emptyPaginationDetails,
      questions: [],
    };
  }
};
