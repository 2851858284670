import ServiceCatalogCategory from "./ServiceCatalogCategory";
import { Category } from "../categories/Category";
import { Department } from "../departments/Department";

interface ServiceCatalogProps {
  department: Department;
  allCategories: Category[];
  childCategories: Category[];
  category?: Category;
  onCategoryClick: (category: Category) => void;
}

export default function ServiceCatalogCategoryGrid({
  department,
  allCategories,
  childCategories,
  category,
  onCategoryClick,
}: ServiceCatalogProps) {
  const renderCategories = () => {
    return childCategories.map((childCategory) => (
      <div className="col-12 md:col-6 lg:col-4" key={childCategory.id}>
        <ServiceCatalogCategory
          department={department}
          allCategories={allCategories}
          category={childCategory}
          onCategoryClick={onCategoryClick}
        ></ServiceCatalogCategory>
      </div>
    ));
  };

  const renderCategory = () => {
    if (!category) {
      return null;
    }

    return (
      <div className="col-6">
        <ServiceCatalogCategory
          department={department}
          allCategories={allCategories}
          category={category}
          onCategoryClick={onCategoryClick}
        ></ServiceCatalogCategory>
      </div>
    );
  };

  return <div className="grid">{childCategories.length ? renderCategories() : renderCategory()}</div>;
}
