import { useEffect, useState } from "react";
import { Dropdown } from "primereact/dropdown";

import { PropsWithClassName } from "../../common/types/PropsWithClassName";
import { fetchTicketStatusesByDepartment } from "./ticketStatusesApi";
import { TicketStatus, allowedTicketStatusesForSelection } from "./TicketStatus";

interface TicketStatusDropdownProps extends PropsWithClassName {
  departmentId: string;
  value?: string;
  showClear?: boolean;
  onChange: (ticketStatusId?: string) => void;
}

export default function TicketStatusDropdown({ className, departmentId, value, showClear, onChange }: TicketStatusDropdownProps) {
  const [ticketStatuses, setTicketStatuses] = useState<TicketStatus[]>([]);
  const selectedTicketStatus = ticketStatuses.find((ticketStatus) => ticketStatus.id === value);

  useEffect(() => {
    const doFetchTicketStatuses = async () => {
      if (departmentId) {
        const ticketStatuses = await fetchTicketStatusesByDepartment(departmentId);
        const allowedStatuses = ticketStatuses.filter((ticketStatus) => {
          if (ticketStatus.id === value) {
            return true;
          }
          return allowedTicketStatusesForSelection.find((x) => x === ticketStatus.id);
        });
        setTicketStatuses(allowedStatuses);
      }
    };
    doFetchTicketStatuses();
  }, [departmentId]);

  return (
    <Dropdown
      className={`${className}`}
      scrollHeight="300px"
      optionLabel="name"
      options={ticketStatuses}
      value={selectedTicketStatus}
      showClear={showClear}
      onChange={(e) => onChange(e.value?.id)}
    />
  );
}
