import { useSelector } from "react-redux";
import { Dropdown } from "primereact/dropdown";

import { selectAllUserRoles } from "./userRolesSlice";

interface UserRoleDropdownProps {
  value?: string;
  onChange: (userRoleId: string) => void;
  showClear?: boolean;
  disabled?: boolean;
}

export default function UserRolesDropdown({ value, onChange, showClear, disabled }: UserRoleDropdownProps) {
  const userRoles = useSelector(selectAllUserRoles);
  const selectedUserRole = userRoles.find((role) => role.id === value);

  return (
    <Dropdown
      placeholder="Rol"
      optionLabel="name"
      options={userRoles}
      showClear={showClear}
      value={selectedUserRole}
      disabled={disabled}
      onChange={(e) => onChange(e.value?.id)}
    />
  );
}
