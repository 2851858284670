import { SelectButton } from "primereact/selectbutton";
import { addMonths, addYears, endOfMonth, endOfToday, endOfYear, startOfMonth, startOfToday, startOfYear } from "date-fns";

import { convertDateToDateString } from "../../common/utils/dateHelper";
import { RelativeDate } from "./RelativeDate";
import { PropsWithClassName } from "../../common/types/PropsWithClassName";

interface RelativeDatesProps extends PropsWithClassName {
  value?: string;
  onChange: (relativeDateId: string) => void;
}

export const relativeDates: Record<string, RelativeDate> = {
  today: {
    id: "today",
    name: "Astazi",
    startDate: convertDateToDateString(startOfToday())!,
    endDate: convertDateToDateString(endOfToday())!,
  },
  currentMonth: {
    id: "currentMonth",
    name: "Luna curenta",
    startDate: convertDateToDateString(startOfMonth(new Date()))!,
    endDate: convertDateToDateString(endOfMonth(new Date()))!,
  },
  currentYear: {
    id: "currentYear",
    name: "Anul curent",
    startDate: convertDateToDateString(startOfYear(new Date()))!,
    endDate: convertDateToDateString(endOfYear(new Date()))!,
  },
  lastMonth: {
    id: "lastMonth",
    name: "Luna trecuta",
    startDate: convertDateToDateString(startOfMonth(addMonths(new Date(), -1)))!,
    endDate: convertDateToDateString(endOfMonth(addMonths(new Date(), -1)))!,
  },
  last3Months: {
    id: "last3Months",
    name: "Ultimele 3 luni",
    startDate: convertDateToDateString(startOfMonth(addMonths(new Date(), -3)))!,
    endDate: convertDateToDateString(endOfMonth(addMonths(new Date(), -1)))!,
  },
  lastYear: {
    id: "lastYear",
    name: "Ultimul an",
    startDate: convertDateToDateString(startOfYear(addYears(new Date(), -1)))!,
    endDate: convertDateToDateString(endOfYear(addYears(new Date(), -1)))!,
  },
};

export default function ReportsFiltersRelativeDates({ className, value, onChange }: RelativeDatesProps) {
  const options = Object.values(relativeDates);
  const selectedOption = options.find((option) => option.id === value);

  return (
    <SelectButton
      className={`${className} select-button-small`}
      allowEmpty={false}
      optionLabel="name"
      options={options}
      value={selectedOption}
      onChange={(e) => onChange(e.target.value.id)}
    ></SelectButton>
  );
}
