import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Card } from "primereact/card";
import { DataTable } from "primereact/datatable";
import { MultiSelect } from "primereact/multiselect";
import { Column } from "primereact/column";
import { Button } from "primereact/button";

import { Ticket } from "../tickets/Ticket";
import { routes } from "../../common/routing/routes";
import {
  defaultPageSize,
  defaultPaginationOptions,
  emptyPaginationDetails,
  getPaginationFirst,
  getPaginationOptionsFromDataTable,
} from "../../common/api/paginationHelper";
import { searchAllTickets } from "../tickets/ticketsApi";
import {
  ColumnDef,
  fetchVisibleColumns,
  persistVisibleColumns,
  columns,
  fetchFilters,
  persistFilters,
  clearPersistedVisibleColumns,
  clearPersistedFilters,
} from "./TicketsTableColumns";
import { selectAuthUser, selectProfile } from "../auth/authSlice";
import { canAccess } from "../auth/utils";
import { Privilege } from "../auth/Privilege";
import { useAppSelector } from "../../common/hooks/useStore";
import { selectDepartmentById } from "../departments/departmentsSlice";
import { useSkipFirstRender } from "../../common/hooks/useSkipFirstRender";
import TicketStatusButtons from "../ticketStatuses/TicketStatusButtons";
import TicketActions from "./TicketActions";
import NoResultsFound from "../../common/components/noResultsFound/NoResultsFound";

interface TicketsTableProps {
  departmentId: string;
}

export default function TicketsTable({ departmentId }: TicketsTableProps) {
  const navigate = useNavigate();
  const authUser = useSelector(selectAuthUser);
  const profile = useSelector(selectProfile);
  const department = useAppSelector((state) => selectDepartmentById(state, departmentId));
  const isAdmin = canAccess(Privilege.Admin, authUser.privilege);
  const [tickets, setTickets] = useState<Ticket[]>([]);
  const [selectedTickets, setSelectedTickets] = useState<Ticket[]>([]);
  const [pagination, setPagination] = useState(emptyPaginationDetails);
  const [first, setFirst] = useState(() => getPaginationFirst(pagination.pageNumber, defaultPageSize));
  const [rows, setRows] = useState(defaultPageSize);
  const [filters, setFilters] = useState<any>(() => fetchFilters(profile.teamIds[0]));
  const agentsFilterTeamId = filters.teamId.value || profile.teamIds[0] || department?.defaultTeamId;
  const [visibleColumns, setVisibleColumns] = useState<ColumnDef[]>(() => fetchVisibleColumns({ departmentId, agentsFilterTeamId }));

  useEffect(() => {
    fetchTickets();
    const intervalId = setInterval(fetchTickets, 1000 * 60);
    return () => clearInterval(intervalId);
  }, [first, rows, filters, departmentId]);

  useSkipFirstRender(() => {
    resetToDefaults();
  }, [departmentId]);

  const fetchTickets = async () => {
    if (departmentId) {
      const response = await searchAllTickets({
        departmentId,
        id: filters.prettyId.value,
        statuses: filters.status.value,
        urgency: filters.urgency.value,
        teamId: filters.teamId.value,
        agentId: filters.agentId.value,
        sortParameters: [
          {
            columnName: "Created",
            isDescending: true,
          },
          {
            columnName: "SlaEndDate",
            isDescending: false,
          },
        ],
        ...getPaginationOptionsFromDataTable(first, rows),
      });
      setVisibleColumns(fetchVisibleColumns({ departmentId, agentsFilterTeamId }));
      setPagination(response.pagination);
      setTickets(response.tickets);
      setSelectedTickets((prevSelectedTickets) => response.tickets.filter((t) => prevSelectedTickets.some((st) => st.id === t.id)));
    }
  };

  const onTicketStatusesFilterChange = (ticketStatuses: string[] | undefined) => {
    const newFilters = { ...filters, status: { ...filters.status, value: ticketStatuses } };
    setFilters(newFilters);
    persistFilters(newFilters);
  };

  const onTicketActionFinished = () => {
    setSelectedTickets([]);
    fetchTickets();
  };

  const resetToDefaults = () => {
    clearPersistedVisibleColumns();
    clearPersistedFilters();
    setVisibleColumns(fetchVisibleColumns({ departmentId, agentsFilterTeamId }, true));
    setFilters(fetchFilters(profile.teamIds[0], true));
    setPagination(emptyPaginationDetails);
    setFirst(getPaginationFirst(emptyPaginationDetails.pageNumber, defaultPageSize));
    setRows(defaultPageSize);
  };

  return (
    <div className="grid">
      <div className={`${selectedTickets.length ? "col-12 xl:col-8" : "col-12"}`}>
        <Card
          title={
            <div className="flex align-items-center justify-content-between">
              <div className="text-2xl font-bold">Tichete</div>
              <TicketStatusButtons inline value={filters.status.value} onChange={onTicketStatusesFilterChange}></TicketStatusButtons>
            </div>
          }
        >
          <div className="flex flex-wrap gap-2 mb-4">
            <MultiSelect
              style={{ maxWidth: "80%" }}
              display="chip"
              dataKey="header"
              optionLabel="header"
              options={columns({ departmentId, agentsFilterTeamId })}
              value={visibleColumns}
              onChange={(e) => {
                setVisibleColumns(e.value);
                persistVisibleColumns(e.value);
              }}
            />
            <Button type="button" text severity="secondary" onClick={() => resetToDefaults()}>
              Reseteaza
            </Button>
          </div>
          <DataTable
            pt={{
              column: {
                headerFilterClearButton: {
                  className: "display-none",
                },
              },
            }}
            rowClassName={() => "cursor-pointer"}
            size="small"
            lazy
            selectionMode="checkbox"
            selection={selectedTickets}
            paginator
            first={first}
            rows={rows}
            rowsPerPageOptions={defaultPaginationOptions}
            totalRecords={pagination.totalCount}
            filterDisplay="row"
            filters={filters}
            filterDelay={500}
            value={tickets}
            emptyMessage={<NoResultsFound />}
            onSelectionChange={(e) => {
              e.originalEvent.stopPropagation();
              setSelectedTickets(e.value);
            }}
            onPage={(e) => {
              setFirst(e.first);
              setRows(e.rows);
            }}
            onFilter={(e) => {
              setFilters(e.filters);
              persistFilters(e.filters);
            }}
            onRowClick={(e) => {
              if (!(e.originalEvent.target as any)?.className.includes("p-selection-column")) {
                navigate(`${routes.agentTicketDetails.navigatePath}/${e.data.id}`);
              }
            }}
          >
            {isAdmin && <Column className="w-3rem" selectionMode="multiple"></Column>}
            {visibleColumns.map((col, index) => col.column(`${index}`))}
          </DataTable>
        </Card>
      </div>
      {!!selectedTickets.length && (
        <div className="col-12 xl:col-4">
          <Card title={`Editeaza ${selectedTickets.length} tichete`}>
            <TicketActions
              className="mb-2"
              ticketIds={selectedTickets.map((t) => t.id)}
              onActionFinished={onTicketActionFinished}
            ></TicketActions>
          </Card>
        </div>
      )}
    </div>
  );
}
