import { useRef } from "react";
import { Button } from "primereact/button";
import { Panel } from "primereact/panel";

import { Question } from "./Question";
import { dislikeQuestion, likeQuestion, viewQuestion } from "./questionsApi";

interface QuestionPanelProps {
  question: Question;
}

export default function QuestionPanel({ question }: QuestionPanelProps) {
  const panelRef = useRef<Panel>(null);

  return (
    <Panel
      ref={panelRef}
      className="mb-1"
      toggleable
      collapsed
      header={
        <div className="flex align-items-center gap-4">
          <span className="flex-1 cursor-pointer" onClick={(event) => panelRef.current?.toggle(event)}>
            {question.questionText}
          </span>
          <div className="flex align-items-center gap-2">
            <i className="pi pi-eye text-600"></i>
            <span>{question.views}</span>
          </div>
          <div className="flex align-items-center gap-2">
            <i className="pi pi-thumbs-up text-600"></i>
            <span>{question.absoluteLikes}</span>
          </div>
        </div>
      }
      onExpand={() => viewQuestion(question)}
    >
      <p className="raw-html-container" dangerouslySetInnerHTML={{ __html: question.answerText }}></p>
      <div className="flex gap-2">
        <Button type="button" icon="pi pi-thumbs-up" rounded outlined onClick={() => likeQuestion(question)} />
        <Button type="button" icon="pi pi-thumbs-down" rounded outlined severity="secondary" onClick={() => dislikeQuestion(question)} />
      </div>
    </Panel>
  );
}
