import axios from "axios";
import { toast } from "react-toastify";

import { TicketStatus } from "./TicketStatus";

const TICKET_STATUSES_API_URL = `${process.env.REACT_APP_API_BASE_URL}/departments`;

export const fetchTicketStatusesByDepartment = async (departmentId: string) => {
  try {
    const response = await axios.get(`${TICKET_STATUSES_API_URL}/${departmentId}/statuses`);
    const ticketStatuses: TicketStatus[] = response.data.map(mapApiTicketStatus);
    return ticketStatuses;
  } catch (err) {
    return [];
  }
};

export const fetchAllTicketStatusesByDepartment = async (departmentId: string) => {
  try {
    const response = await axios.get(`${TICKET_STATUSES_API_URL}/${departmentId}/all-statuses`);
    const ticketStatuses: TicketStatus[] = response.data.map(mapApiTicketStatus);
    return ticketStatuses;
  } catch (err) {
    return [];
  }
};

export const updateTicketStatus = async (ticketStatus: TicketStatus) => {
  const payload = {
    id: parseInt(ticketStatus.id),
    departmentId: parseInt(ticketStatus.departmentId),
    name: ticketStatus.name,
    isEnabled: ticketStatus.isEnabled,
  };
  await axios.put(`${TICKET_STATUSES_API_URL}/status`, payload);
  toast.success("Status tichet a fost actualizat cu succes!");
};

export const mapApiTicketStatus = (ticketStatus: any): TicketStatus => ({
  id: `${ticketStatus.id}`,
  departmentId: `${ticketStatus.departmentId}`,
  name: ticketStatus.name,
  systemName: ticketStatus.ticketStatus?.typeName,
  isEnabled: ticketStatus.isEnabled,
});
