import axios from "axios";
import { toast } from "react-toastify";

import { SearchTicketCostsFilter, TicketCost } from "../ticketCost/TicketCost";

const TICKETS_API_URL = `${process.env.REACT_APP_API_BASE_URL}/tickets`;

export const quickSearchCostCenters = async (filter: SearchTicketCostsFilter) => {
  const response = await axios.put(`${TICKETS_API_URL}/search-costcenters`, {
    departmentId: parseInt(filter.departmentId),
    term: filter.term,
  });
  const costCenters: string[] = response.data;
  return costCenters;
};

export const saveTicketCost = async (ticketIds: string[], ticketCost: TicketCost) => {
  const payload = {
    ticketIds: ticketIds.map((id) => parseInt(id)),
    costCenter: ticketCost.costCenter,
    cost: ticketCost.cost,
  };
  await axios.put(`${TICKETS_API_URL}/save-cost`, payload);
  toast.success("Cost salvat cu succes!");
};
