import { Card } from "primereact/card";
import { Column } from "primereact/column";
import { DataTable, DataTableRowEditCompleteEvent } from "primereact/datatable";
import { InputNumber } from "primereact/inputnumber";
import { useEffect, useState } from "react";

import UrgencyTag from "../urgencies/UrgencyTag";
import { Priority } from "../priorities/Priority";
import { fetchPriorities, updatePriority } from "../priorities/proritiesApi";
import NoResultsFound from "../../common/components/noResultsFound/NoResultsFound";

interface PrioritiesTableProps {
  departmentId: string;
}

export default function PrioritiesTable({ departmentId }: PrioritiesTableProps) {
  const [priorities, setPriorities] = useState<Priority[]>([]);

  useEffect(() => {
    doFetchPriorities();
  }, [departmentId]);

  const doFetchPriorities = async () => {
    if (departmentId) {
      const priorities = await fetchPriorities(departmentId);
      setPriorities(priorities);
    }
  };

  const onRowEditComplete = async (event: DataTableRowEditCompleteEvent) => {
    const updatedPriority: Priority = {
      departmentId: event.newData.departmentId,
      urgency: event.newData.urgency,
      businessImpact: event.newData.urgency,
      ticketPriority: event.newData.urgency,
      slaMinutes: event.newData.slaMinutes,
    };

    const _priorities = [...priorities];
    _priorities[event.index] = updatedPriority;
    setPriorities(_priorities);

    await updatePriority(updatedPriority);
    await doFetchPriorities();
  };

  return (
    <Card title="Configurare SLA">
      <DataTable size="small" editMode="row" value={priorities} onRowEditComplete={onRowEditComplete} emptyMessage={<NoResultsFound />}>
        <Column
          className="w-9rem"
          field="urgency"
          header="Prioritate"
          body={(rowData: Priority) => <UrgencyTag className="h-2rem" value={rowData.urgency}></UrgencyTag>}
        ></Column>
        <Column
          className="w-13rem"
          field="slaMinutes"
          header="Minute working hours"
          body={(rowData: Priority) => (
            <InputNumber className="p-inputtext-sm w-full" size={5} useGrouping={false} value={rowData.slaMinutes} disabled />
          )}
          editor={(options) => (
            <InputNumber
              className="p-inputtext-sm w-full"
              size={5}
              max={262800}
              useGrouping={false}
              value={options.value}
              onValueChange={(e) => options.editorCallback!(e.target.value)}
            />
          )}
        ></Column>
        <Column className="text-right" rowEditor />
      </DataTable>
    </Card>
  );
}
