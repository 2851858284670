import axios from "axios";
import { toast } from "react-toastify";

import { Priority } from "./Priority";

const PRIORITIES_API_URL = `${process.env.REACT_APP_API_BASE_URL}/priorities`;

export const fetchPriorities = async (departmentId: string) => {
  try {
    const response = await axios.get(`${PRIORITIES_API_URL}/${departmentId}`);
    const priorities: Priority[] = response.data.map((priority: any) => ({
      departmentId: `${priority.departmentId}`,
      urgency: `${priority.urgency}`,
      businessImpact: `${priority.businessImpact}`,
      ticketPriority: `${priority.ticketPriority}`,
      slaMinutes: priority.slaMinutes,
    }));
    return priorities;
  } catch (err) {
    return [];
  }
};

export const updatePriority = async (priority: Priority) => {
  const payload = {
    departmentId: parseInt(priority.departmentId),
    urgency: parseInt(priority.urgency),
    businessImpact: parseInt(priority.businessImpact),
    ticketPriority: parseInt(priority.ticketPriority),
    slaMinutes: priority.slaMinutes,
  };
  await axios.put(PRIORITIES_API_URL, payload);
  toast.success("Nivelul de urgenta a fost actualizat cu succes!");
};
