import { Dropdown } from "primereact/dropdown";
import { useEffect, useState } from "react";

import { PropsWithClassName } from "../../common/types/PropsWithClassName";
import { User } from "./User";
import { fetchTeamUsers } from "./usersApi";
import UserDropdownItem from "./UserDropdownItem";
import NoResultsFound from "../../common/components/noResultsFound/NoResultsFound";

interface UserSelectProps extends PropsWithClassName {
  placeholder?: string;
  teamId?: string;
  value?: string;
  onChange?: (userId: string) => void;
}

export default function UsersSelect({ className, placeholder, teamId, value, onChange }: UserSelectProps) {
  const [users, setUsers] = useState<User[]>([]);
  const [selectedUser, setSelectedUser] = useState<User>();

  useEffect(() => {
    const selectedUser = users.find((user) => user.id === value);
    setSelectedUser(selectedUser);
  }, [JSON.stringify(users), value]);

  useEffect(() => {
    const doFetchTeamUsers = async () => {
      if (teamId) {
        const users = await fetchTeamUsers(teamId);
        setUsers(users);
      }
    };
    doFetchTeamUsers();
  }, [teamId]);

  return (
    <Dropdown
      panelClassName="w-25rem"
      className={`${className} w-full`}
      placeholder={placeholder}
      options={users}
      optionLabel="displayName"
      value={selectedUser}
      itemTemplate={(user: User) => <UserDropdownItem user={user} />}
      emptyMessage={<NoResultsFound />}
      onChange={(e) => onChange?.(e.value?.id)}
    />
  );
}
