import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { routes } from "../../common/routing/routes";
import { logout } from "./authSlice";
import { useAppDispatch } from "../../common/hooks/useStore";

export default function LogoutPage() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const doLogout = async () => {
      await dispatch(logout());
      navigate(routes.login.path);
    };
    doLogout();
  }, []);

  return <div>Logging out...</div>;
}
