import { useContext } from "react";
import { Outlet } from "react-router-dom";

import { LayoutContext } from "./LayoutContext";
import Content from "./Content";
import Header from "./Header";
import Sidebar from "./Sidebar";

export default function PrivateLayout() {
  const { closeSidebar } = useContext(LayoutContext);

  return (
    <>
      <Sidebar />
      <div className="flex flex-column h-full">
        <Header />
        <Content onClick={closeSidebar}>
          <Outlet />
        </Content>
      </div>
    </>
  );
}
