import { Privilege } from "../../features/auth/Privilege";

export const routes = {
  login: {
    path: "/login",
    requiredPrivilege: Privilege.Anonymous,
  },
  logout: {
    path: "/logout",
    requiredPrivilege: Privilege.Anonymous,
  },
  unauthorized: {
    path: "/unauthorized",
    requiredPrivilege: Privilege.Anonymous,
  },

  dashboard: {
    path: "/",
    requiredPrivilege: Privilege.User,
  },
  createIncident: {
    path: "/create-incident",
    requiredPrivilege: Privilege.User,
  },
  createRequest: {
    path: "/create-request",
    requiredPrivilege: Privilege.User,
  },
  userTicketDetails: {
    path: "/user-ticket/:ticketId",
    navigatePath: "/user-ticket",
    requiredPrivilege: Privilege.User,
  },

  agentTicketDetails: {
    path: "/agent-ticket/:ticketId",
    navigatePath: "/agent-ticket",
    requiredPrivilege: Privilege.Agent,
  },
  agentTickets: {
    path: "/agent-tickets",
    requiredPrivilege: Privilege.Agent,
  },
  questions: {
    path: "/questions",
    requiredPrivilege: Privilege.Agent,
  },
  myProfile: {
    path: "/my-profile",
    requiredPrivilege: Privilege.Agent,
  },

  reports: {
    path: "/reports",
    requiredPrivilege: Privilege.Admin,
  },
  users: {
    path: "/users",
    requiredPrivilege: Privilege.Admin,
  },
  departments: {
    path: "/departments",
    requiredPrivilege: Privilege.Admin,
  },
  categories: {
    path: "/categories",
    requiredPrivilege: Privilege.Admin,
  },
  priorities: {
    path: "/priorities",
    requiredPrivilege: Privilege.Admin,
  },
  emailTemplates: {
    path: "/email-templates",
    requiredPrivilege: Privilege.Admin,
  },
  workingHours: {
    path: "/working-hours",
    requiredPrivilege: Privilege.Admin,
  },
  systemSetting: {
    path: "/systemSetting",
    requiredPrivilege: Privilege.Admin,
  },
  teams: {
    path: "/teams",
    requiredPrivilege: Privilege.Admin,
  },
  ticketStatuses: {
    path: "/ticket-statuses",
    requiredPrivilege: Privilege.Admin,
  },
};

export const homeRoute = (privilege: Privilege) => {
  const route = privilege === Privilege.Agent ? routes.agentTickets : routes.dashboard;
  return route;
};
