import { useEffect, useState } from "react";
import { Column } from "primereact/column";
import { TreeTable } from "primereact/treetable";
import { Card } from "primereact/card";
import { TreeNode } from "primereact/treenode";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { confirmDialog } from "primereact/confirmdialog";
import { Tag } from "primereact/tag";

import { Category } from "../categories/Category";
import DepartmentsDropdownCard from "../departments/DepartmentsDropdownCard";
import CategoryForm from "./CategoryForm";
import { createCategory, deleteCategory, fetchCategoriesByDepartment, updateCategory } from "../categories/categoriesApi";
import TicketLabel from "../tickets/TicketLabel";
import { LS_SELECTED_DEPARTMENT_ID } from "../../common/utils/common";

function getTreeNodesChildren(categories: Category[], parentId: string): TreeNode[] {
  return categories
    .filter((category) => category.parentId === parentId)
    .map((category) => {
      return {
        id: category.id,
        key: category.id,
        data: category,
        children: getTreeNodesChildren(categories, category.id),
      };
    });
}

function getRootTreeNodes(categories: Category[]) {
  const treeNodes: TreeNode[] = [];

  categories.forEach((category) => {
    if (!category.parentId) {
      treeNodes.push({
        id: category.id,
        key: category.id,
        data: category,
        children: [],
      });
    }
  });
  treeNodes.forEach((treeNode) => {
    treeNode.children = getTreeNodesChildren(categories, treeNode.id!);
  });

  return treeNodes;
}

export default function CategoriesPage() {
  const [allCategories, setAllCategories] = useState<Category[]>([]);
  const [selectedDepartmentId, setSelectedDepartmentId] = useState(localStorage.getItem(LS_SELECTED_DEPARTMENT_ID) || "");
  const [selectedCategory, setSelectedCategory] = useState<Category | undefined>(undefined);
  const [selectedParentCategory, setSelectedParentCategory] = useState<Category | undefined>(undefined);
  const [showCategoryDialog, setShowCategoryDialog] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const treeNodes = getRootTreeNodes(allCategories);

  useEffect(() => {
    fetchCategories();
  }, [selectedDepartmentId]);

  const fetchCategories = async () => {
    if (selectedDepartmentId) {
      const allCategories = await fetchCategoriesByDepartment(selectedDepartmentId);
      setAllCategories(allCategories);
    }
  };

  const closeDialog = () => {
    setSelectedCategory(undefined);
    setShowCategoryDialog(false);
  };

  const onCreate = (parentCategory?: Category) => {
    setSelectedCategory(undefined);
    setSelectedParentCategory(parentCategory);
    setShowCategoryDialog(true);
    setIsEditing(false);
  };

  const onEdit = (category: Category) => {
    setSelectedCategory(category);
    setShowCategoryDialog(true);
    setIsEditing(true);
  };

  const onDelete = (category: Category) => {
    confirmDialog({
      header: "Confirmare stergere",
      icon: "pi pi-exclamation-triangle",
      message: (
        <div className="text-xl">
          Esti sigur ca vrei sa stergi categoria <span className="font-bold">{category.name}</span>?
        </div>
      ),
      acceptLabel: "Da",
      rejectLabel: "Nu",
      accept: async () => {
        await deleteCategory(category);
        await fetchCategories();
      },
    });
  };

  const onSubmit = async (category: Category) => {
    category.departmentId = selectedDepartmentId;
    if (isEditing) {
      await updateCategory(category);
    } else {
      await createCategory(category);
    }
    await fetchCategories();
    setShowCategoryDialog(false);
  };

  return (
    <div className="flex flex-column gap-2">
      <DepartmentsDropdownCard
        showAll
        value={selectedDepartmentId}
        onChange={(department) => setSelectedDepartmentId(department)}
      ></DepartmentsDropdownCard>
      <Card
        title={
          <div className="flex align-items-center justify-content-between">
            <div className="text-2xl font-bold">Categorii</div>
            <Button label="Adauga" icon="pi pi-plus" onClick={() => onCreate()} />
          </div>
        }
      >
        <TreeTable value={treeNodes}>
          <Column
            field="name"
            header="Nume"
            expander
            body={(node: TreeNode) => (
              <div className="inline-flex align-items-center gap-2">
                <span>{node.data.name}</span>
                {node.data.ticketType != null && (
                  <span className="flex gap-2 capitalize">
                    <span className="font-bold">-</span>
                    <TicketLabel ticketType={node.data.ticketType}></TicketLabel>
                  </span>
                )}
              </div>
            )}
          />
          <Column
            className="w-4rem pr-0"
            field="isActive"
            body={(node: TreeNode) => {
              return node.data.isActive ? <Tag severity="success">Activa</Tag> : <Tag severity="danger">Inactiva</Tag>;
            }}
          ></Column>
          <Column
            className="w-11rem pl-0 text-right"
            body={(node: TreeNode) => (
              <>
                {node.data.level !== 2 && <Button size="small" icon="pi pi-plus" rounded text onClick={() => onCreate(node.data)} />}
                <Button size="small" icon="pi pi-pencil" rounded text onClick={() => onEdit(node.data)} />
                <Button size="small" icon="pi pi-trash" rounded text severity="danger" onClick={() => onDelete(node.data)} />
              </>
            )}
          />
        </TreeTable>
        <Dialog
          className="w-full md:w-6"
          header={selectedCategory ? "Editeaza categorie" : "Adauga categorie"}
          visible={showCategoryDialog}
          onHide={() => closeDialog()}
        >
          <CategoryForm
            allCategories={allCategories}
            category={selectedCategory}
            parentCategory={selectedParentCategory}
            onSubmit={onSubmit}
          />
        </Dialog>
      </Card>
    </div>
  );
}
