import { useState } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { Navigate, useSearchParams } from "react-router-dom";

import FormGroup from "../../common/components/form/FormGroup";
import { authenticate, selectAuthUser, selectIsAuthenticated } from "./authSlice";
import { LoginInfo } from "./LoginInfo";
import { useAppDispatch } from "../../common/hooks/useStore";
import { homeRoute } from "../../common/routing/routes";

export default function LoginPage() {
  const [searchParams] = useSearchParams();
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const authUser = useSelector(selectAuthUser);
  const dispatch = useAppDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginInfo>();
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (data: LoginInfo) => {
    setIsLoading(true);
    await dispatch(authenticate(data));
    setIsLoading(false);
  };

  if (isAuthenticated) {
    const defaultPath = homeRoute(authUser?.privilege).path;
    const redirectUrl = decodeURIComponent(searchParams.get("redirectUrl") || "") || defaultPath;
    return <Navigate to={redirectUrl} />;
  }

  return (
    <div className="flex mt-8 surface-section">
      <form className="w-full md:w-6 p-4 md:p-8 surface-section" onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-5 text-center">
          <img className="mb-3" height={125} src="/images/logo.png" alt="Smart Suport Logo" />
          <div className="mb-3 text-3xl font-medium">Autentificare</div>
        </div>
        <FormGroup label="Nume utilizator" errorMessage={errors.username?.message}>
          <InputText type="text" {...register("username", { required: "Nume utilizator este obligatoriu" })} />
        </FormGroup>
        <FormGroup label="Parola" errorMessage={errors.password?.message}>
          <InputText type="password" {...register("password", { required: "Parola este obligatorie" })} />
        </FormGroup>
        <Button type="submit" className="w-full" label="Login" icon="pi pi-sign-in" loading={isLoading} disabled={isLoading} />
      </form>
      <div className="hidden md:block w-6 bg-no-repeat" style={{ backgroundImage: "url('/images/bg-login.jpg')" }}></div>
    </div>
  );
}
