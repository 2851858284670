import { PropsWithChildren } from "react";

import styles from "./ServiceCatalogItem.module.css";

interface ServiceCatalogItemProps extends PropsWithChildren {
  name: string;
  fileUri: string;
  icon: string;
}

export default function ServiceCatalogItem({ name, fileUri, icon, children }: ServiceCatalogItemProps) {
  return (
    <div className={`${children ? "" : "align-items-center"} flex gap-2 h-full p-2 border-1 border-solid border-round surface-border`}>
      {fileUri ? (
        <div className={styles.imageContainer}>
          <img src={fileUri} alt="icon" />
        </div>
      ) : (
        <div className={styles.iconContainer}>
          <i className={`pi ${icon}`}></i>
        </div>
      )}
      <div className="flex flex-column gap-2">
        <div className="text-lg font-medium">{name}</div>
        {children}
      </div>
    </div>
  );
}
