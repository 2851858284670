import { useSelector } from "react-redux";
import { useContext, useEffect, useState } from "react";

import DepartmentsDropdownCard from "../departments/DepartmentsDropdownCard";
import { LayoutContext } from "../../common/components/layout/LayoutContext";
import TicketsTable from "./TicketsTable";
import { selectProfile } from "../auth/authSlice";
import { Privilege } from "../auth/Privilege";
import ProtectedChildren from "../../common/routing/ProtectedChildren";
import { LS_SELECTED_DEPARTMENT_ID } from "../../common/utils/common";

export default function TicketsPage() {
  const { setFullContentWidth, setStandardContentWidth } = useContext(LayoutContext);
  const profile = useSelector(selectProfile);
  const [selectedDepartmentId, setSelectedDepartmentId] = useState(localStorage.getItem(LS_SELECTED_DEPARTMENT_ID) || profile.departmentId);

  useEffect(() => {
    setFullContentWidth();
    return () => setStandardContentWidth();
  }, []);

  return (
    <>
      <ProtectedChildren requiredPrivilege={Privilege.Admin}>
        <DepartmentsDropdownCard
          className="mb-4"
          value={selectedDepartmentId}
          onChange={(department) => setSelectedDepartmentId(department)}
        ></DepartmentsDropdownCard>
      </ProtectedChildren>
      <TicketsTable departmentId={selectedDepartmentId}></TicketsTable>
    </>
  );
}
