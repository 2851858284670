import { BreadCrumb } from "primereact/breadcrumb";
import { MenuItem } from "primereact/menuitem";

import { Category } from "../categories/Category";
import { Department } from "../departments/Department";

interface ServiceCatalogBreadCrumbProps {
  department: Department;
  allCategories: Category[];
  category?: Category;
  onCategoryClick: (category?: Category) => void;
}

export const getCategoriesTree = (categories: Category[], id: string | undefined) => {
  const currentCategory = categories.find((category) => category.id === id);
  if (!currentCategory) {
    return [];
  }

  const categoriesTree: Category[] = [currentCategory];
  let parentId = currentCategory.parentId;
  while (parentId) {
    // eslint-disable-next-line no-loop-func
    const parentCategory = categories.find((category) => category.id === parentId);
    if (!parentCategory) {
      break;
    }
    categoriesTree.unshift(parentCategory);
    parentId = parentCategory.parentId;
  }
  return categoriesTree;
};

export default function ServiceCatalogBreadCrumb({ department, allCategories, category, onCategoryClick }: ServiceCatalogBreadCrumbProps) {
  const categories = getCategoriesTree(allCategories, category?.id);

  const homeMenuItem: MenuItem = {
    label: department.name,
    command: () => onCategoryClick(undefined),
  };
  const menuItems: MenuItem[] = (categories || []).map((category) => ({
    label: category.name,
    command: () => onCategoryClick(category),
  }));

  return (
    <BreadCrumb
      model={menuItems}
      home={homeMenuItem}
      pt={{
        menu: {
          className: "flex-wrap gap-2",
        },
        separator: {
          className: "m-0",
        },
      }}
    ></BreadCrumb>
  );
}
