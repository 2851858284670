import { Dropdown } from "primereact/dropdown";

import { TicketType, ticketTypes } from "./TicketType";

interface TicketTypeDropdownProps {
  placeholder?: string;
  showClear?: boolean;
  value?: TicketType;
  onChange: (ticketTypeId: TicketType) => void;
}

export default function TicketTypeDropdown({ value, onChange, ...rest }: TicketTypeDropdownProps) {
  const selectedTicketType = ticketTypes.find((ticketType) => ticketType.id === value);

  return (
    <Dropdown
      optionLabel="name"
      options={ticketTypes}
      value={selectedTicketType}
      onChange={(e) => onChange(e.value?.id)}
      {...rest}
    ></Dropdown>
  );
}
