import { useState } from "react";
import { Dialog } from "primereact/dialog";

import { PropsWithClassName } from "../../common/types/PropsWithClassName";

export default function EmailTemplateLegend({ className }: PropsWithClassName) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={`${className} inline-block`}>
      <i className="pi pi-info-circle cursor-pointer" onClick={() => setIsOpen(true)} />
      <Dialog className="w-full sm:w-6" header="Tokenuri sablonuri email" visible={isOpen} onHide={() => setIsOpen(false)}>
        <div className="flex flex-column gap-2">
          <div className="mb-2">
            Aceste etichete populează dinamic informații despre un tichet de suport, incluzând detalii personale, identificatori, prioritate
            și categorizare. Conținutul variază în funcție de datele specifice ale utilizatorului și tichetului în sistem.
          </div>
          <div>
            <b>&#123;&#123;UserCompany&#125;&#125;</b>: Numele companiei de care utilizatorul aparține.
          </div>
          <div>
            <b>&#123;&#123;UserLocation&#125;&#125;</b>: Locația geografică a utilizatorului: Oraș, stat sau țară.
          </div>
          <div>
            <b>&#123;&#123;UserFullName&#125;&#125;</b>: Numele complet al utilizatorului pentru personalizare.
          </div>
          <div>
            <b>&#123;&#123;TicketTitle&#125;&#125;</b>: Subiectul sau titlul tichetului de suport.
          </div>
          <div>
            <b>&#123;&#123;TicketNumber&#125;&#125;</b>: Identificator unic pentru un tichet de suport.
          </div>
          <div>
            <b>&#123;&#123;TicketUrl&#125;&#125;</b>: Link unic pentru un tichet de suport.
          </div>
          <div>
            <b>&#123;&#123;TicketPriority&#125;&#125;</b>: Nivelul de prioritate al tichetului: &quot;Critic,&quot; &quot;Urgent,&quot;
            &quot;Normal,&quot;
          </div>
          <div>
            <b>&#123;&#123;TicketCategory&#125;&#125;</b>: Categoria sau tipul larg al tichetului: &quot;Problemă Tehnică,&quot;
            &quot;Întrebare despre Facturare.&quot;
          </div>
          <div>
            <b>&#123;&#123;TicketSubCategory&#125;&#125;</b>: Subcategorie specifică în cadrul tichetului: &quot;Defect Software,&quot;
            &quot;Discrepanță de Plată.&quot;
          </div>
          <div>
            <b>&#123;&#123;TicketThirdCategory&#125;&#125;</b>: Clasificare detaliată în ierarhia tichetului.
          </div>
          <div>
            <b>&#123;&#123;TicketNote&#125;&#125;</b>: Note sau comentarii asociate tichetului pentru context suplimentar.
          </div>
          <div>
            <b>&#123;&#123;TicketNoteAttachments&#125;&#125;</b>: Numele fisierelor atasate in comentariu.
          </div>
          <div>
            <b>&#123;&#123;TicketCost&#125;&#125;</b>: Costul asociat cu tichetul de suport.
          </div>
          <div>
            <b>&#123;&#123;TicketSupplier&#125;&#125;</b>: Furnizorul sau partenerul asociat cu tichetul de suport.
          </div>
          <div>
            <b>&#123;&#123;MergedTicketNumber&#125;&#125;</b>: Numarul ticketului cu care a fost unit.
          </div>
        </div>
      </Dialog>
    </div>
  );
}
