import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { InputText } from "primereact/inputtext";
import { Editor, EditorTextChangeEvent } from "primereact/editor";
import { Button } from "primereact/button";

import { EmailTemplate } from "../emailTemplates/EmailTemplate";
import EmailTemplateLegend from "./EmailTemplateLegend";
import QuillBar from "../../common/components/quillBar/QuillBar";
import Label from "../../common/components/form/Label";
import FormGroup from "../../common/components/form/FormGroup";

interface EmailTemplateFormProps {
  emailTemplate?: EmailTemplate;
  onSubmit: (emailTemplate: EmailTemplate) => Promise<void>;
}

export default function EmailTemplateForm(props: EmailTemplateFormProps) {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<EmailTemplate>({ defaultValues: props.emailTemplate });
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (data: EmailTemplate) => {
    setIsLoading(true);
    await props.onSubmit(data);
    setIsLoading(false);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormGroup label="Subiect *" errorMessage={errors.subject?.message}>
        <InputText {...register("subject", { required: "Subiectul este obligatoriu" })} />
      </FormGroup>
      <FormGroup errorMessage={errors.body?.message}>
        <Label>
          Continut * <EmailTemplateLegend className="ml-2"></EmailTemplateLegend>
        </Label>
        <Controller
          name="body"
          control={control}
          rules={{
            required: "Continutul emailului este obligatoriu",
            minLength: { value: 3, message: "Continutul trebuie să aibă cel puțin 3 caractere" },
            maxLength: { value: 4000, message: "Continutul nu poate avea mai mult de 4000 de caractere" },
          }}
          render={({ field }) => (
            <Editor
              name="body"
              value={field.value}
              headerTemplate={<QuillBar />}
              onTextChange={(e: EditorTextChangeEvent) => field.onChange(e.htmlValue)}
            />
          )}
        ></Controller>
      </FormGroup>
      <Button type="submit" className="block ml-auto" label="Salveaza" icon="pi pi-save" loading={isLoading} disabled={isLoading} />
    </form>
  );
}
