import { PaginationOptions, PaginationDetails } from "../types/Pagination";

export const defaultPageSize = 10;

export const defaultPaginationOptions = [defaultPageSize, 25, 50, 100];

export const emptyPaginationDetails: PaginationDetails = {
  hasNextPage: false,
  hasPreviousPage: false,
  pageNumber: 0,
  totalPages: 0,
  totalCount: 0,
};

export const getPaginationFirst = (pageNumber: number, pageSize: number): number => {
  return pageNumber * pageSize;
};

export const getPaginationOptionsFromDataTable = (first: number, rows: number): PaginationOptions => {
  return {
    pageNumber: Math.floor(first / rows),
    pageSize: rows,
  };
};

export function mapToApiPagination(paginationOptions: PaginationOptions): PaginationOptions {
  return {
    pageNumber: paginationOptions.pageNumber + 1,
    pageSize: paginationOptions.pageSize,
  };
}

export function mapApiPaginationDetails(responseData: any): PaginationDetails {
  if (!responseData) {
    return emptyPaginationDetails;
  }
  return {
    hasNextPage: responseData.hasNextPage,
    hasPreviousPage: responseData.hasPreviousPage,
    pageNumber: responseData.pageNumber - 1,
    totalPages: responseData.totalPages,
    totalCount: responseData.totalCount,
  };
}
