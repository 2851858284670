import { Category } from "../categories/Category";
import ServiceCatalogCategoryActions from "./ServiceCatalogCategoryActions";
import ServiceCatalogItem from "./ServiceCatalogItem";
import { Department } from "../departments/Department";

interface CategoryCardProps {
  department: Department;
  allCategories: Category[];
  category: Category;
  onCategoryClick: (category: Category) => void;
}

const categoryBorderClass = "pr-2 border-right-1 border-right-solid surface-border";

export default function ServiceCatalogCategory({ department, allCategories, category, onCategoryClick }: CategoryCardProps) {
  const childCategories = allCategories.filter((c) => c.parentId === category.id);

  return (
    <ServiceCatalogItem name={category.name} fileUri={category.fileUri} icon={category.icon}>
      {childCategories.length ? (
        <div className="flex flex-wrap gap-2">
          {childCategories.map((category, index) => {
            const isLast = index === childCategories.length - 1;
            return (
              <div
                className={`${isLast ? "" : categoryBorderClass} flex align-items-center gap-1 text-xs cursor-pointer hover:underline`}
                key={category.id}
                onClick={() => onCategoryClick(category)}
              >
                {category.name}
              </div>
            );
          })}
        </div>
      ) : (
        <ServiceCatalogCategoryActions department={department} category={category}></ServiceCatalogCategoryActions>
      )}
    </ServiceCatalogItem>
  );
}
