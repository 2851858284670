import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputSwitch } from "primereact/inputswitch";

import FormGroup from "../../common/components/form/FormGroup";
import { SystemSettings } from "../systemSettings/SystemSettings";

interface SmtpSettingsFormProps {
  systemSettings: SystemSettings;
  onSubmit: (systemSettings: SystemSettings) => Promise<void>;
}

export default function SmtpSettingsForm(props: SmtpSettingsFormProps) {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<SystemSettings>({
    defaultValues: {
      ...props.systemSettings,
    },
  });
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (data: any) => {
    setIsLoading(true);
    await props.onSubmit(data);
    setIsLoading(false);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormGroup label="Server email" errorMessage={errors.server?.message}>
        <InputText {...register("server", { required: "Server email este obligatoriu" })} />
      </FormGroup>
      <FormGroup label="Trimis de (email)" errorMessage={errors.from?.message}>
        <InputText {...register("from", { required: "Trimis de (email) este obligatoriu" })} />
      </FormGroup>
      <FormGroup label="Trimis de (denumire)" errorMessage={errors.fromDisplayName?.message}>
        <InputText {...register("fromDisplayName", { required: "Trimis de (denumire) este obligatoriu" })} />
      </FormGroup>
      <FormGroup label="Username" errorMessage={errors.username?.message}>
        <InputText {...register("username", { required: "Username este obligatoriu" })} />
      </FormGroup>
      <FormGroup label="Parola" errorMessage={errors.password?.message}>
        <InputText type="password" {...register("password", { required: "Parola este obligatorie" })} />
      </FormGroup>
      <FormGroup label="Port" errorMessage={errors.port?.message}>
        <InputText keyfilter="int" {...register("port", { required: "Portul este obligatoriu" })} />
      </FormGroup>
      <FormGroup label="TLS/SSL" inline errorMessage={errors.secureConnection?.message}>
        <Controller
          name="secureConnection"
          control={control}
          render={({ field }) => <InputSwitch checked={field.value} onChange={(e) => field.onChange(e.value)} />}
        ></Controller>
      </FormGroup>
      <Button type="submit" className="block ml-auto" label="Salveaza" icon="pi pi-save" loading={isLoading} disabled={isLoading} />
    </form>
  );
}
