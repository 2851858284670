import { Dropdown } from "primereact/dropdown";

import { PropsWithClassName } from "../../common/types/PropsWithClassName";
import { groupedTicketStatusOptions, TicketStatusEnum } from "./TicketStatus";

interface TicketStatusDropdownGroupedProps extends PropsWithClassName {
  placeholder?: string;
  showClear?: boolean;
  value?: TicketStatusEnum[];
  onChange: (ticketStatusIds: TicketStatusEnum[] | undefined) => void;
}

export default function TicketStatusDropdownGrouped({ className, onChange, ...rest }: TicketStatusDropdownGroupedProps) {
  return (
    <Dropdown
      className={`${className}`}
      scrollHeight="300px"
      optionLabel="name"
      options={groupedTicketStatusOptions}
      onChange={(e) => onChange(e.value)}
      {...rest}
    ></Dropdown>
  );
}
