import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";

import NoteFormInput, { noteFormInputDefaultValidation } from "../note/NoteFormInput";
import CategoriesDropdown from "../categories/CategoriesDropdown";
import UrgencyDropdown from "../urgencies/UrgenciesDropdown";
import TicketLabel from "../tickets/TicketLabel";
import FormGroup from "../../common/components/form/FormGroup";
import DepartmentsDropdown from "../departments/DepartmentsDropdown";
import CreateTicketSuggestions from "./CreateTicketSuggestions";
import TeamsDropdown from "../teams/TeamsDropdown";
import UsersSelect from "../users/UsersSelect";
import { routes } from "../../common/routing/routes";
import { Ticket } from "../tickets/Ticket";
import { TicketType } from "../ticketType/TicketType";
import { selectAuthUser } from "../auth/authSlice";
import { createTicket } from "../tickets/ticketsApi";
import { Note } from "../note/Note";
import { useAppSelector } from "../../common/hooks/useStore";
import { selectDepartmentById } from "../departments/departmentsSlice";

interface CreateTicketProps {
  ticketType: TicketType;
}

type TickerFormData = Ticket & { note: Note };

export default function CreateTicketPage({ ticketType }: CreateTicketProps) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [selectedDepartmentId, setSelectedDepartmentId] = useState(searchParams.get("departmentId") || "");
  const [isLoading, setIsLoading] = useState(false);
  const authUser = useSelector(selectAuthUser);
  const department = useAppSelector((state) => selectDepartmentById(state, selectedDepartmentId));
  const {
    register,
    control,
    handleSubmit,
    getValues,
    watch,
    reset,
    formState: { errors },
  } = useForm<TickerFormData>({
    defaultValues: {
      categoryId: searchParams.get("categoryId") || "",
    },
  });
  const watchedSubject = watch("subject");
  const watchedTeamId = watch("teamId");

  useEffect(() => {
    reset();
  }, [ticketType]);

  const onSubmit = async (ticketFormData: TickerFormData) => {
    ticketFormData.ownerId = authUser!.id;
    ticketFormData.type = ticketType;
    setIsLoading(true);
    const newTicket = await createTicket(ticketFormData, ticketFormData.note);
    setIsLoading(false);
    navigate(`${routes.userTicketDetails.navigatePath}/${newTicket.id}`);
  };

  return (
    <Card title={<TicketLabel ticketType={ticketType} creating colored iconClassName="text-2xl"></TicketLabel>}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-column">
          <FormGroup label="Departament">
            <DepartmentsDropdown value={selectedDepartmentId} onChange={(department) => setSelectedDepartmentId(department)} />
          </FormGroup>
          <FormGroup label="Categorie *" errorMessage={errors.categoryId?.message}>
            <Controller
              name="categoryId"
              control={control}
              rules={{ required: "Categoria este obligatorie" }}
              render={({ field }) => (
                <CategoriesDropdown
                  ticketType={ticketType}
                  departmentId={selectedDepartmentId}
                  value={field.value}
                  onChange={field.onChange}
                ></CategoriesDropdown>
              )}
            ></Controller>
          </FormGroup>
          {department?.agentRequiredOnTicketCreation && (
            <div className="grid">
              <FormGroup className="col" label="Grupa *" errorMessage={errors.teamId?.message}>
                <Controller
                  name="teamId"
                  control={control}
                  rules={{ required: "Grupa este obligatorie" }}
                  render={({ field }) => (
                    <TeamsDropdown
                      placeholder="Selecteaza grupa"
                      departmentId={department.id}
                      value={field.value}
                      onChange={(e) => field.onChange(e)}
                    ></TeamsDropdown>
                  )}
                ></Controller>
              </FormGroup>
              <FormGroup className="col" label="Responsabil *" errorMessage={errors.agentId?.message}>
                <Controller
                  name="agentId"
                  control={control}
                  rules={{ required: "Responsabilul este obligatoriu" }}
                  render={({ field }) => (
                    <UsersSelect
                      placeholder="Selecteaza responsabil"
                      teamId={watchedTeamId}
                      value={field.value}
                      onChange={(e) => field.onChange(e)}
                    ></UsersSelect>
                  )}
                ></Controller>
              </FormGroup>
            </div>
          )}
          <FormGroup label="Titlu *" errorMessage={errors.subject?.message}>
            <InputText {...register("subject", { required: "Titlu este obligatoriu" })} />
            <CreateTicketSuggestions departmentId={selectedDepartmentId} searchTerm={watchedSubject}></CreateTicketSuggestions>
          </FormGroup>
          <FormGroup label="Descriere *">
            <Controller
              name="note"
              control={control}
              rules={{
                validate: {
                  ...noteFormInputDefaultValidation,
                  required: (value) => {
                    if (!value?.noteText?.trim()) {
                      return "Descrierea este obligatorie";
                    }
                  },
                },
              }}
              render={({ field }) => (
                <NoteFormInput
                  noteText={field.value?.noteText}
                  noteAttachments={field.value?.noteAttachments}
                  errorMessage={errors.note?.message}
                  onChange={(noteChangeEvent) => {
                    field.onChange({
                      ...getValues("note"),
                      ...noteChangeEvent,
                    });
                  }}
                />
              )}
            ></Controller>
          </FormGroup>
          {ticketType === TicketType.Incident && (
            <FormGroup label="Prioritate *" errorMessage={errors.urgency?.message}>
              <Controller
                name="urgency"
                control={control}
                rules={{ required: "Nivelul de urgenta este obligatoriu" }}
                render={({ field }) => <UrgencyDropdown value={field.value} onChange={field.onChange}></UrgencyDropdown>}
              ></Controller>
            </FormGroup>
          )}
          <div className="flex justify-content-end gap-2">
            <Button type="button" label="Inapoi" text severity="secondary" onClick={() => navigate(-1)} />
            <Button type="submit" label="Trimite ticket" icon="pi pi-send" loading={isLoading} disabled={isLoading} />
          </div>
        </div>
      </form>
    </Card>
  );
}
