import { PropsWithChildren } from "react";
import { Message } from "primereact/message";

import Label from "./Label";
import { PropsWithClassName } from "../../types/PropsWithClassName";

interface FormGroupProps extends PropsWithChildren, PropsWithClassName {
  label?: string;
  errorMessage?: string;
  size?: "small" | "large";
  inline?: boolean;
  checkbox?: boolean;
}

export default function FormGroup({ className, label, errorMessage, size, inline, checkbox, children }: FormGroupProps) {
  const isSmall = size === "small";

  return checkbox ? (
    <Label className={`${className} ${isSmall ? "mb-1 gap-1" : "mb-4 gap-2"} flex align-items-center cursor-pointer`}>
      {children}
      {label}
    </Label>
  ) : (
    <div className={`${className} ${isSmall ? "mb-1 gap-1" : "mb-4 gap-2"} ${inline ? "flex-row align-items-center" : "flex-column"} flex`}>
      {label && <Label>{label}</Label>}
      {children}
      {errorMessage && <Message severity="error" text={errorMessage}></Message>}
    </div>
  );
}
