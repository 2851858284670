import { useEffect, useState } from "react";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";

import { PropsWithClassName } from "../../common/types/PropsWithClassName";
import { Category } from "./Category";
import NoResultsFound from "../../common/components/noResultsFound/NoResultsFound";
import { TicketType } from "../ticketType/TicketType";
import { fetchCategoriesByDepartment } from "./categoriesApi";

interface CategoriesDropdownProps extends PropsWithClassName {
  column?: boolean;
  ticketType: TicketType;
  departmentId: string;
  value?: string;
  onChange: (categoryId?: string) => void;
}

export default function CategoriesDropdown({ className, column, ticketType, departmentId, value, onChange }: CategoriesDropdownProps) {
  const [allCategories, setAllCategories] = useState<Category[]>([]);
  const [selectedFirstLevelCategory, setSelectedFirstLevelCategory] = useState<Category | undefined>(undefined);
  const [selectedSecondLevelCategory, setSelectedSecondLevelCategory] = useState<Category | undefined>(undefined);
  const [selectedThirdLevelCategory, setSelectedThirdLevelCategory] = useState<Category | undefined>(undefined);

  const allFirstLevelCategories = allCategories.filter((category) => category.level === 0);
  const allSecondLevelCategories = allCategories.filter((category) => category.level === 1);
  const allThirdLevelCategories = allCategories.filter((category) => category.level === 2);
  const secondLevelCategories = allSecondLevelCategories.filter((secondLevelCategory) => {
    const hasChildThirdLevelCategories = allThirdLevelCategories.some((thirdLevelCategory) => {
      return secondLevelCategory.id === thirdLevelCategory.parentId && thirdLevelCategory.ticketType === ticketType;
    });
    return secondLevelCategory.parentId === selectedFirstLevelCategory?.id && hasChildThirdLevelCategories;
  });
  const thirdLevelCategories = allCategories.filter(
    (category) => category.parentId === selectedSecondLevelCategory?.id && category.ticketType === ticketType,
  );

  useEffect(() => {
    const fetchCategories = async () => {
      if (departmentId) {
        const allCategories = await fetchCategoriesByDepartment(departmentId);
        const activeCategories = allCategories.filter((category) => (category.id !== value ? category.isActive : true));
        setAllCategories(activeCategories);
      }
    };
    fetchCategories();
  }, [departmentId]);

  useEffect(() => {
    if (!allThirdLevelCategories.length) {
      return;
    }

    const thirdLevelCategory = allThirdLevelCategories.find((category) => category.id === value);
    const secondLevelCategory = allSecondLevelCategories.find((category) => category.id === thirdLevelCategory?.parentId);
    const firstLevelCategory = allFirstLevelCategories.find((category) => category.id === secondLevelCategory?.parentId);
    setSelectedFirstLevelCategory(firstLevelCategory || selectedFirstLevelCategory);
    setSelectedSecondLevelCategory(secondLevelCategory || selectedSecondLevelCategory);
    handleThirdLevelCategoryChange(thirdLevelCategory);
  }, [value, `${allThirdLevelCategories}`]);

  const handleThirdLevelCategoryChange = (category?: Category) => {
    setSelectedThirdLevelCategory(category);
    if (value !== category?.id) {
      onChange(category?.id);
    }
  };

  const onFirstLevelCategoryChange = (e: DropdownChangeEvent) => {
    setSelectedFirstLevelCategory(e.value);
    setSelectedSecondLevelCategory(undefined);
    handleThirdLevelCategoryChange(undefined);
  };

  const onSecondLevelCategoryChange = (e: DropdownChangeEvent) => {
    setSelectedSecondLevelCategory(e.value);
    handleThirdLevelCategoryChange(undefined);
  };

  const onThirdLevelCategoryChange = (e: DropdownChangeEvent) => {
    handleThirdLevelCategoryChange(e.value);
  };

  return (
    <div className={`${className} ${column ? "flex-column" : "flex-row"} flex flex-wrap md:flex-nowrap gap-2`}>
      <Dropdown
        className={`${column ? "w-full" : "w-full md:w-4"}`}
        optionLabel="name"
        scrollHeight="300px"
        options={allFirstLevelCategories}
        value={selectedFirstLevelCategory}
        emptyMessage={<NoResultsFound />}
        onChange={onFirstLevelCategoryChange}
      />
      <Dropdown
        className={`${column ? "w-full" : "w-full md:w-4"}`}
        optionLabel="name"
        scrollHeight="300px"
        options={secondLevelCategories}
        value={selectedSecondLevelCategory}
        emptyMessage={<NoResultsFound />}
        onChange={onSecondLevelCategoryChange}
      />
      <Dropdown
        className={`${column ? "w-full" : "w-full md:w-4"}`}
        optionLabel="name"
        scrollHeight="300px"
        options={thirdLevelCategories}
        value={selectedThirdLevelCategory}
        emptyMessage={<NoResultsFound />}
        onChange={onThirdLevelCategoryChange}
      />
    </div>
  );
}
