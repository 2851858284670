import { Privilege } from "./Privilege";

export function canAccess(requiredPrivilege: number | undefined, userPrivilege: number | undefined) {
  if (!requiredPrivilege || requiredPrivilege === Privilege.Anonymous) {
    return true;
  }
  if (!userPrivilege) {
    return false;
  }
  return userPrivilege >= requiredPrivilege;
}
