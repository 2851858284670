import { useEffect, useState } from "react";
import { Column } from "primereact/column";
import { Calendar } from "primereact/calendar";
import { InputSwitch } from "primereact/inputswitch";
import { DataTable, DataTableRowEditCompleteEvent } from "primereact/datatable";

import DayOfWeekLabel from "../daysOfWeek/DayOfWeekLabel";
import { WorkingHours } from "../workingHours/WorkingHours";
import { convertDateToTimeString, convertTimeStringToDate } from "../../common/utils/dateHelper";
import NoResultsFound from "../../common/components/noResultsFound/NoResultsFound";
import { fetchWorkingHours, updateWorkingHour } from "../workingHours/workingHoursApi";

interface WorkingHoursTableProps {
  departmentId: string;
}

export default function WorkingHoursTable({ departmentId }: WorkingHoursTableProps) {
  const [workingHours, setWorkingHours] = useState<WorkingHours[]>([]);

  useEffect(() => {
    doFetchWorkingHours();
  }, [departmentId]);

  const doFetchWorkingHours = async () => {
    if (departmentId) {
      const workingHours = await fetchWorkingHours(departmentId);
      setWorkingHours(workingHours);
    }
  };

  const onRowEditComplete = async (event: DataTableRowEditCompleteEvent) => {
    const updatedWorkingHour: WorkingHours = {
      id: event.newData.id,
      departmentId,
      start: event.newData.start,
      end: event.newData.end,
      isWorkingDay: event.newData.isWorkingDay,
    };

    const _workingHours = [...workingHours];
    _workingHours[event.index] = updatedWorkingHour;
    setWorkingHours(_workingHours);

    await updateWorkingHour(updatedWorkingHour);
    await doFetchWorkingHours();
  };

  return (
    <DataTable size="small" editMode="row" value={workingHours} emptyMessage={<NoResultsFound />} onRowEditComplete={onRowEditComplete}>
      <Column
        className="w-5rem"
        field="isWorkingDay"
        body={(rowData: WorkingHours) => <InputSwitch checked={rowData.isWorkingDay} disabled />}
        editor={(options) => <InputSwitch checked={options.value} onChange={(e) => options.editorCallback!(e.value)} />}
      />
      <Column className="w-7rem" header="Zi" body={(rowData: WorkingHours) => <DayOfWeekLabel value={rowData.id} />}></Column>
      <Column
        className="w-7rem"
        header="Inceput"
        field="start"
        body={(rowData: WorkingHours) => <Calendar timeOnly value={convertTimeStringToDate(rowData.start)} disabled />}
        editor={(options) => (
          <Calendar
            timeOnly
            value={convertTimeStringToDate(options.value)}
            onChange={(e) => options.editorCallback!(convertDateToTimeString(e.target.value ?? undefined))}
          />
        )}
      ></Column>
      <Column
        className="w-7rem"
        header="Sfarsit"
        field="end"
        body={(rowData: WorkingHours) => <Calendar value={convertTimeStringToDate(rowData.end)} timeOnly disabled />}
        editor={(options) => (
          <Calendar
            timeOnly
            value={convertTimeStringToDate(options.value)}
            onChange={(e) => options.editorCallback!(convertDateToTimeString(e.target.value ?? undefined))}
          />
        )}
      ></Column>
      <Column className="text-right" rowEditor />
    </DataTable>
  );
}
