import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Dropdown } from "primereact/dropdown";

import { selectActiveDepartments, selectAllDepartments } from "./departmentsSlice";
import { PropsWithClassName } from "../../common/types/PropsWithClassName";
import NoResultsFound from "../../common/components/noResultsFound/NoResultsFound";

interface DepartmentsDropdownProps extends PropsWithClassName {
  showAll?: boolean;
  value?: string;
  onChange: (departmentId: string) => void;
  disabled?: boolean;
}

export default function DepartmentsDropdown({ className, showAll, value, onChange, disabled }: DepartmentsDropdownProps) {
  const departments = useSelector(showAll ? selectAllDepartments : selectActiveDepartments);
  const selectedDepartment = departments.find((department) => department.id === value);

  useEffect(() => {
    if (!value && departments[0]) {
      onChange(departments[0].id);
    }
  }, [departments]);

  return (
    <Dropdown
      className={`${className}`}
      placeholder="Selecteaza departament"
      optionLabel="name"
      options={departments}
      value={selectedDepartment}
      emptyMessage={<NoResultsFound />}
      disabled={disabled}
      onChange={(e) => onChange(e.value.id)}
    />
  );
}
