import { useContext, PropsWithChildren } from "react";

import { LayoutContext } from "./LayoutContext";
import styles from "./Content.module.css";

interface ContentProps extends PropsWithChildren {
  onClick: () => void;
}

export default function Content({ children, onClick }: ContentProps) {
  const { contentWidth } = useContext(LayoutContext);

  return (
    <div className={`${styles.layoutContentContainer} flex-1 h-full p-1 sm:p-2 md:p-4 surface-ground`} onClick={onClick}>
      <div style={{ maxWidth: contentWidth }} className={`${styles.layoutContent} h-full`}>
        {children}
      </div>
    </div>
  );
}
