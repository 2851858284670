import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Paginator } from "primereact/paginator";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { toast } from "react-toastify";

import { fetchTicket } from "../tickets/ticketsApi";
import { createNote, searchExternalNotes } from "../note/notesApi";
import {
  defaultPageSize,
  defaultPaginationOptions,
  emptyPaginationDetails,
  getPaginationFirst,
  getPaginationOptionsFromDataTable,
} from "../../common/api/paginationHelper";
import { Ticket } from "../tickets/Ticket";
import { Note } from "../note/Note";
import NoteDetails from "../note/NoteDetails";
import TicketAddNoteForm from "../tickets/TicketAddNoteForm";
import { canAccess } from "../auth/utils";
import { routes } from "../../common/routing/routes";
import { Privilege } from "../auth/Privilege";
import { selectAuthUser } from "../auth/authSlice";
import UrgencyTag from "../urgencies/UrgencyTag";
import FormGroup from "../../common/components/form/FormGroup";
import { User } from "../users/User";
import { fetchUser } from "../users/usersApi";
import { LayoutContext } from "../../common/components/layout/LayoutContext";
import useAreTicketActionsAllowed from "../tickets/useAreTicketActionsAllowed";
import { TicketStatusEnum } from "../ticketStatuses/TicketStatus";
import { useReplacementTicketWarning } from "../tickets/useReplacementTicketWarning";
import TicketCostForm from "../ticketCost/TicketCostForm";
import { selectDepartmentById } from "../departments/departmentsSlice";
import { useAppSelector } from "../../common/hooks/useStore";

export default function UserTicketDetailsPage() {
  const { setFullContentWidth, setStandardContentWidth } = useContext(LayoutContext);
  const navigate = useNavigate();
  const { ticketId } = useParams();
  const authUser = useSelector(selectAuthUser);
  const [pagination, setPagination] = useState(emptyPaginationDetails);
  const [first, setFirst] = useState(getPaginationFirst(pagination.pageNumber, defaultPageSize));
  const [rows, setRows] = useState(defaultPageSize);
  const [ticket, setTicket] = useState<Ticket>();
  const [agent, setAgent] = useState<User>();
  const [notes, setNotes] = useState<Note[]>([]);
  const department = useAppSelector((state) => selectDepartmentById(state, ticket?.category?.departmentId));
  const areTicketActionsAllowed = useAreTicketActionsAllowed(ticket);
  const shouldReopen = ticket?.status === TicketStatusEnum.Resolved;

  useReplacementTicketWarning(routes.userTicketDetails.navigatePath, ticket);

  useEffect(() => {
    setFullContentWidth();
    return () => setStandardContentWidth();
  }, []);

  useEffect(() => {
    if (ticketId) {
      doFetchTicket(ticketId);
    }
  }, [ticketId]);

  useEffect(() => {
    if (ticketId) {
      doSearchNotes(ticketId);
    }
  }, [first, rows, ticketId]);

  const doFetchTicket = async (ticketId: string) => {
    const ticket = await fetchTicket(ticketId);
    setTicket(ticket);
    if (ticket.agentId) {
      const agent = await fetchUser(ticket.agentId);
      setAgent(agent);
    }
  };

  const doSearchNotes = async (ticketId: string) => {
    const notesResponse = await searchExternalNotes({
      ticketId,
      ...getPaginationOptionsFromDataTable(first, rows),
    });
    setNotes(notesResponse.notes);
    setPagination(notesResponse.pagination);
  };

  const doCreateNote = async (newNote: Partial<Note>) => {
    await createNote(newNote);
    await doSearchNotes(ticket!.id);
    if (shouldReopen) {
      toast.success(`Tichetul ${ticket.prettyId} a fost redeschis cu succes!`);
      await doFetchTicket(ticket!.id);
    }
  };

  if (canAccess(Privilege.Agent, authUser?.privilege) && ticketId) {
    return <Navigate to={`${routes.agentTicketDetails.navigatePath}/${ticketId}`} />;
  }

  return ticket ? (
    <div className="grid">
      <div className="col-12 xl:col-8">
        <div className="mb-4">
          {notes.map((note) => (
            <NoteDetails key={note.id} className="mb-2" note={note}></NoteDetails>
          ))}
          <Paginator
            first={first}
            rows={rows}
            rowsPerPageOptions={defaultPaginationOptions}
            totalRecords={pagination.totalCount}
            onPageChange={(e) => {
              setFirst(e.first);
              setRows(e.rows);
            }}
          />
        </div>
        <Card className="mb-4" title="Adauga nota">
          <TicketAddNoteForm
            showInternal={false}
            ticketId={ticketId!}
            reopen={shouldReopen}
            disabled={shouldReopen ? false : !areTicketActionsAllowed}
            onSubmit={doCreateNote}
          ></TicketAddNoteForm>
        </Card>
        {department?.costCentersEnabled && (
          <Card title="Cost tichet">
            <TicketCostForm ticket={ticket} readonly></TicketCostForm>
          </Card>
        )}
      </div>
      <div className="col-12 xl:col-4">
        <Card
          title={
            <div className="flex align-items-center gap-2">
              <span>Tichet {ticket.prettyId}</span>
              <UrgencyTag value={ticket.urgency}></UrgencyTag>
            </div>
          }
        >
          <FormGroup label="Titlu">
            <span className="text-600">{ticket.subject}</span>
          </FormGroup>
          <FormGroup label="Responsabil">
            <span className="text-600">{agent?.displayName || "-"}</span>
          </FormGroup>
          <FormGroup label="Data sesizarii">
            <span className="text-600">{ticket.createdPretty}</span>
          </FormGroup>
          <FormGroup label="Status">
            <span className="text-600">{ticket.statusType.name}</span>
          </FormGroup>
          <Button type="button" className="block ml-auto" label="Inapoi" text severity="secondary" onClick={() => navigate(-1)} />
        </Card>
      </div>
    </div>
  ) : null;
}
