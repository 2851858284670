import { useEffect, useRef, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";

import NoResultsFound from "../../common/components/noResultsFound/NoResultsFound";
import UserDropdownItem from "./UserDropdownItem";
import { PropsWithClassName } from "../../common/types/PropsWithClassName";
import { User } from "./User";
import { UserRoleEnum } from "../userRoles/UserRole";
import { fetchUser, quickSearchUsers } from "./usersApi";

interface UsersDropdownProps extends PropsWithClassName {
  placeholder?: string;
  showClear?: boolean;
  disabled?: boolean;
  teamIds?: string[];
  role?: string;
  value?: string;
  onChange?: (userId: string) => void;
}

export default function UsersDropdown({ className, placeholder, showClear, disabled, teamIds, role, value, onChange }: UsersDropdownProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [users, setUsers] = useState<User[]>([]);
  const [selectedUser, setSelectedUser] = useState<User>();
  const [searchTerm, setSearchTerm] = useState("");
  const options = users.length ? users : selectedUser ? [selectedUser] : [];

  useEffect(() => {
    const selectedUser = users.find((user) => user.id === value);
    setSelectedUser(selectedUser);
  }, [JSON.stringify(users), value]);

  useEffect(() => {
    const timeoutId = setTimeout(() => doQuickSearchUsers(), 500);
    return () => clearTimeout(timeoutId);
  }, [searchTerm]);

  useEffect(() => {
    populateAllUsers();
  }, [JSON.stringify(teamIds), role]);

  const doQuickSearchUsers = async () => {
    if (searchTerm) {
      const users = await quickSearchUsers({ teamIds, role, searchTerm });
      const shouldAddSelectedUser = selectedUser && !users.some((user) => user.id === selectedUser.id);
      setUsers(shouldAddSelectedUser ? [selectedUser, ...users] : users);
    } else {
      populateAllUsers();
    }
  };

  const populateAllUsers = async () => {
    if (role === UserRoleEnum.Agent) {
      const users = await quickSearchUsers({ teamIds, role });
      setUsers(users);
    } else if (value) {
      const currentUser = await fetchUser(value);
      setUsers([currentUser]);
    }
  };

  return (
    <Dropdown
      panelClassName="w-25rem"
      className={`${className} w-full`}
      placeholder={placeholder}
      options={options}
      optionLabel="displayName"
      value={selectedUser}
      itemTemplate={(user: User) => <UserDropdownItem user={user} />}
      filter
      filterTemplate={
        <InputText
          ref={inputRef}
          className="w-full"
          placeholder="Cauta utilizator"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      }
      showClear={showClear}
      disabled={disabled}
      emptyMessage={<NoResultsFound />}
      onShow={() => inputRef.current?.focus({ preventScroll: true })}
      onChange={(e) => onChange?.(e.value?.id)}
    />
  );
}
