import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";

import { routes } from "../../common/routing/routes";

export default function UnauthorizedPage() {
  const navigate = useNavigate();

  return (
    <div className="mt-8 p-8 surface-section text-center md:text-right">
      <div className="my-6 text-6xl font-bold">Neautorizat!</div>
      <p className="mb-6 text-3xl">Nu ai suficiente drepturi pentru a accesa aceasta sectiune :(</p>
      <Button label="Inapoi la pagina principala" icon="pi pi-home" outlined onClick={() => navigate(routes.dashboard.path)}></Button>
    </div>
  );
}
