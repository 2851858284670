import { useEffect, useState } from "react";
import { DataTable, DataTableRowEditCompleteEvent } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import { Card } from "primereact/card";

import { fetchAllTicketStatusesByDepartment, updateTicketStatus } from "../ticketStatuses/ticketStatusesApi";
import { TicketStatus } from "../ticketStatuses/TicketStatus";
import NoResultsFound from "../../common/components/noResultsFound/NoResultsFound";

interface TicketStatusesTableProps {
  departmentId: string;
}

export default function TicketStatusesTable({ departmentId }: TicketStatusesTableProps) {
  const [ticketStatuses, setTicketStatuses] = useState<TicketStatus[]>([]);

  useEffect(() => {
    doFetchTicketStatuses();
  }, [departmentId]);

  const doFetchTicketStatuses = async () => {
    if (departmentId) {
      const ticketStatuses = await fetchAllTicketStatusesByDepartment(departmentId);
      setTicketStatuses(ticketStatuses);
    }
  };

  const onRowEditComplete = async (event: DataTableRowEditCompleteEvent) => {
    const updatedTicketStatus: TicketStatus = {
      id: event.newData.id,
      departmentId,
      name: event.newData.name,
      systemName: event.newData.systemName,
      isEnabled: event.newData.isEnabled,
    };

    const _ticketStatuses = [...ticketStatuses];
    _ticketStatuses[event.index] = updatedTicketStatus;
    setTicketStatuses(_ticketStatuses);

    await updateTicketStatus(updatedTicketStatus);
    await doFetchTicketStatuses();
  };

  return (
    <Card title="Statusuri tichete">
      <DataTable size="small" editMode="row" value={ticketStatuses} emptyMessage={<NoResultsFound />} onRowEditComplete={onRowEditComplete}>
        <Column
          className="w-5rem"
          field="isEnabled"
          body={(rowData: TicketStatus) => <InputSwitch checked={rowData.isEnabled} disabled />}
          editor={(options) => <InputSwitch checked={options.value} onChange={(e) => options.editorCallback!(e.value)} />}
        />
        <Column className="w-15rem" field="systemName" header="Nume sistem"></Column>
        <Column
          className="w-25rem"
          field="name"
          header="Nume interfata"
          body={(rowData: TicketStatus) => <InputText className="p-inputtext-sm w-full" value={rowData.name} disabled />}
          editor={(options) => (
            <InputText className="p-inputtext-sm w-full" value={options.value} onChange={(e) => options.editorCallback!(e.target.value)} />
          )}
        ></Column>
        <Column className="text-right" rowEditor />
      </DataTable>
    </Card>
  );
}
