import { memo, useState } from "react";
import { Card } from "primereact/card";

import { ReportsFilters } from "./ReportsFilters";
import { fetchLoadReport } from "./reportsApi";
import { LoadReport } from "./LoadReport";
import ReportsFiltersForm from "./ReportsFiltersForm";
import LoadReportPieChartTitle from "./LoadReportPieChartTitle";
import LoadReportPieChart from "./LoadReportPieChart";
import LoadReportBarChart from "./LoadReportBarChart";
import TicketsReportTable from "./TicketReportTable";

const LoadReportPieChartMemo = memo(LoadReportPieChart);
const LoadReportBarChartMemo = memo(LoadReportBarChart);

export default function ReportsPage() {
  const [filters, setFilters] = useState<ReportsFilters>();
  const [departmentLoadReport, setDepartmentLoadReport] = useState<LoadReport>();
  const [agentLoadReport, setAgentLoadReport] = useState<LoadReport>();
  const [teamLoadReport, setTeamLoadReport] = useState<LoadReport>();

  const onSubmit = async (filters: ReportsFilters) => {
    setFilters(filters);

    const departmentLoadReport = await fetchLoadReport({
      ...filters,
      agentId: undefined,
      teamId: undefined,
    });
    setDepartmentLoadReport(departmentLoadReport);

    if (filters.teamId) {
      const teamLoadReport = await fetchLoadReport({
        ...filters,
        agentId: undefined,
      });
      setTeamLoadReport(teamLoadReport);
    } else {
      setTeamLoadReport(undefined);
    }

    if (filters.agentId) {
      const agentLoadReport = await fetchLoadReport(filters);
      setAgentLoadReport(agentLoadReport);
    } else {
      setAgentLoadReport(undefined);
    }
  };

  return (
    <div className="flex flex-column gap-2">
      <Card title="Rapoarte">
        <ReportsFiltersForm onSubmit={onSubmit} />
      </Card>
      {departmentLoadReport && (
        <Card title={<LoadReportPieChartTitle title="Incarcare departament" totalTickets={departmentLoadReport.totalTickets} />}>
          <LoadReportPieChartMemo value={departmentLoadReport} />
        </Card>
      )}
      {teamLoadReport && (
        <Card title={<LoadReportPieChartTitle title="Incarcare grupa" totalTickets={teamLoadReport.totalTickets} />}>
          <LoadReportPieChartMemo value={teamLoadReport}></LoadReportPieChartMemo>
        </Card>
      )}
      {agentLoadReport && (
        <Card title={<LoadReportPieChartTitle title="Incarcare agent" totalTickets={agentLoadReport.totalTickets} />}>
          <LoadReportPieChartMemo value={agentLoadReport}></LoadReportPieChartMemo>
        </Card>
      )}

      {teamLoadReport && agentLoadReport && (
        <Card title="Incarcare agent si grupa">
          <LoadReportBarChartMemo teamLoadReport={teamLoadReport} agentLoadReport={agentLoadReport}></LoadReportBarChartMemo>
        </Card>
      )}
      {filters && (
        <Card
          title={
            <div className="flex align-items-center justify-content-between">
              <div className="text-2xl font-bold">Tichete</div>
              {/* <ProtectedChildren requiredPrivilege={Privilege.Admin}>
                <Button label="Export" icon="pi pi-file-export" />
              </ProtectedChildren> */}
            </div>
          }
        >
          <TicketsReportTable filters={filters}></TicketsReportTable>
        </Card>
      )}
    </div>
  );
}
