import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";

import { User } from "../users/User";
import DepartmentsDropdown from "../departments/DepartmentsDropdown";
import UserRolesDropdown from "../userRoles/UserRoleDropdown";
import FormGroup from "../../common/components/form/FormGroup";
import { UserRoleEnum } from "../userRoles/UserRole";
import TeamsDropdown from "../teams/TeamsDropdown";
import UsersDropdown from "./UsersDropdown";

interface UserFormProps {
  user?: User;
  readOnly?: boolean;
  onSubmit?: (user: User) => Promise<void>;
}

export default function UserForm(props: UserFormProps) {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<User>({ defaultValues: props.user });
  const [isLoading, setIsLoading] = useState(false);
  const watchedDepartmentId = watch("departmentId");
  const watchedUserRole = watch("role");
  const isAgent = watchedUserRole === UserRoleEnum.Agent;

  const onSubmit = async (data: User) => {
    setIsLoading(true);
    await props.onSubmit?.(data);
    setIsLoading(false);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="grid mb-2">
        <FormGroup className={isAgent ? "col-4" : "col-12"} label="Rol" errorMessage={errors.role?.message} size="small">
          <Controller
            name="role"
            control={control}
            rules={{ required: "Rolul este obligatoriu" }}
            render={({ field }) => (
              <UserRolesDropdown
                value={field.value}
                onChange={(selectedValue) => field.onChange(selectedValue)}
                disabled={props.readOnly}
              />
            )}
          />
        </FormGroup>
        {isAgent && (
          <>
            <FormGroup className="col-4" label="Departament" errorMessage={errors.departmentId?.message} size="small">
              <Controller
                name="departmentId"
                control={control}
                rules={{ required: "Departamentul este obligatoriu" }}
                render={({ field }) => (
                  <DepartmentsDropdown
                    showAll
                    value={field.value}
                    onChange={(selectedValue) => field.onChange(selectedValue)}
                    disabled={props.readOnly}
                  />
                )}
              />
            </FormGroup>
            <FormGroup className="col-4" label="Grupa" size="small">
              <Controller
                name="teamIds"
                control={control}
                render={({ field }) => (
                  <TeamsDropdown
                    departmentId={watchedDepartmentId}
                    value={field.value[0]}
                    onChange={(selectedValue) => field.onChange([selectedValue])}
                  />
                )}
              />
            </FormGroup>
          </>
        )}
        <FormGroup className="col-4" label="Nume utilizator" size="small">
          <InputText disabled {...register("username")} />
        </FormGroup>
        <FormGroup className="col-4" label="Nume" size="small">
          <InputText disabled {...register("lastName")} />
        </FormGroup>
        <FormGroup className="col-4" label="Prenume" size="small">
          <InputText disabled {...register("firstName")} />
        </FormGroup>
        <FormGroup className="col-6" label="Email" size="small">
          <InputText disabled {...register("emailAddress")} />
        </FormGroup>
        <FormGroup className="col-6" label="Email secundar" size="small">
          <InputText disabled {...register("secondaryEmailAddress")} />
        </FormGroup>
        <FormGroup className="col-6" label="Numar telefon" size="small">
          <InputText disabled {...register("phoneNumber")} />
        </FormGroup>
        <FormGroup className="col-6" label="Numar mobil" size="small">
          <InputText disabled {...register("mobileNumber")} />
        </FormGroup>
        <FormGroup className="col-6" label="Manager" size="small">
          <Controller
            name="managerId"
            control={control}
            render={({ field }) => (
              <UsersDropdown placeholder="Selecteaza manager" value={field.value} onChange={field.onChange}></UsersDropdown>
            )}
          />
        </FormGroup>
        <FormGroup className="col-6" label="Companie" size="small">
          <InputText disabled {...register("company")} />
        </FormGroup>
        <FormGroup className="col-6" label="Locatie" size="small">
          <InputText disabled {...register("location")} />
        </FormGroup>
        <FormGroup className="col-6" label="Cod postal" size="small">
          <InputText disabled {...register("postalCode")} />
        </FormGroup>
      </div>
      {!props.readOnly && (
        <Button type="submit" className="block ml-auto" label="Salveaza" icon="pi pi-save" loading={isLoading} disabled={isLoading} />
      )}
    </form>
  );
}
