import { useState } from "react";
import { useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { InputSwitch } from "primereact/inputswitch";
import { Button } from "primereact/button";
import { confirmDialog } from "primereact/confirmdialog";

import FormGroup from "../../common/components/form/FormGroup";
import NoteFormInput, { noteFormInputDefaultValidation } from "../note/NoteFormInput";
import { Note } from "../note/Note";
import { selectAuthUser } from "../auth/authSlice";
import { NoteType } from "../note/NoteType";

interface TicketAddNoteFormProps {
  ticketId: string;
  showInternal: boolean;
  reopen?: boolean;
  disabled?: boolean;
  onSubmit: (note: Partial<Note>) => Promise<void>;
}

interface NoteFormData {
  note: Note;
  isInternal: boolean;
}

export default function TicketAddNoteForm(props: TicketAddNoteFormProps) {
  const authUser = useSelector(selectAuthUser);
  const {
    control,
    handleSubmit,
    reset,
    watch,
    getValues,
    formState: { errors },
  } = useForm<NoteFormData>({
    defaultValues: {
      isInternal: false,
      note: {
        noteText: "",
        noteAttachments: [],
      },
    },
  });
  const [isLoading, setIsLoading] = useState(false);
  const watchedIsInternal = watch("isInternal");
  const shouldReopen = props.reopen && !watchedIsInternal;

  const onSubmit = async (noteFormData: NoteFormData) => {
    if (shouldReopen) {
      confirmDialog({
        header: "Confirmare redeschidere tichet",
        icon: "pi pi-exclamation-triangle",
        message: <div className="text-xl">Esti sigur ca vrei sa redeschizi tichetul?</div>,
        acceptLabel: "Da",
        rejectLabel: "Nu",
        accept: () => doSubmit(noteFormData),
      });
    } else {
      doSubmit(noteFormData);
    }
  };

  const doSubmit = async (noteFormData: NoteFormData) => {
    const newNote: Partial<Note> = {
      type: noteFormData.isInternal ? NoteType.Internal : NoteType.External,
      ticketId: props.ticketId,
      userId: authUser!.id,
      noteText: noteFormData.note.noteText,
      noteAttachments: noteFormData.note.noteAttachments,
    };
    setIsLoading(true);
    await props.onSubmit(newNote);
    setIsLoading(false);
    reset();
  };

  return (
    <form className={`${props.disabled ? "opacity-50 pointer-events-none" : ""}`} onSubmit={handleSubmit(onSubmit)}>
      {props.showInternal && (
        <FormGroup label="Nota interna" inline>
          <Controller
            name="isInternal"
            control={control}
            render={({ field }) => <InputSwitch checked={field.value} onChange={(e) => field.onChange(e.value)} />}
          ></Controller>
        </FormGroup>
      )}
      <FormGroup>
        <Controller
          name="note"
          control={control}
          rules={{
            validate: noteFormInputDefaultValidation,
          }}
          render={({ field }) => (
            <NoteFormInput
              noteText={field.value?.noteText}
              noteAttachments={field.value?.noteAttachments}
              errorMessage={errors.note?.message}
              onChange={(noteChangeEvent) => {
                field.onChange({
                  ...getValues("note"),
                  ...noteChangeEvent,
                });
              }}
            />
          )}
        ></Controller>
      </FormGroup>
      <Button
        className="block ml-auto"
        label={shouldReopen ? "Redeschide" : "Trimite"}
        icon="pi pi-send"
        loading={isLoading}
        disabled={isLoading}
      ></Button>
    </form>
  );
}
